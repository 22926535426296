<div class="modal-header">
    <h3 class="modal-title pull-left"><i class="fa fa-check"></i> Subscription History</h3>
    <lib-button
        [smType]="'button'"
        [smClass]="'btn-close pull-right'"
        (smClick)="cancel()"
    ></lib-button>
</div>
<form class="form-horizontal" #frm="ngForm" novalidate>
    <div class="modal-body">
        <div class="col-md-12 form-group">
            <table class="table table-hover table-condensed table-bordered">
                <thead>
                <tr>
                    <th class="col-md-1">
                        <strong>Time Stamp</strong>
                    </th>
                    <th class="col-md-1">
                        <strong>Start</strong>
                    </th>
                    <th class="col-md-1">
                        <strong>Expiry</strong>
                    </th>
                    <th class="col-md-1">
                        <strong>Type</strong>
                    </th>
                    <th class="col-md-1">
                        <strong>Payment</strong>
                    </th>
                    <th class="col-md-1">
                        <strong>Status</strong>
                    </th>
                    <th class="col-md-2">
                        <strong>Comment</strong>
                    </th>
                    <th class="col-md-4">
                        <strong>Extra Info</strong>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let o of subscriptionHistory">
                    <td>{{o.timeStamp|date:'dd MMM yyyy'}} <small>{{o.timeStamp|date:'HH:mm'}}</small></td>
                    <td>{{o.start|date:'dd MMM yyyy'}}</td>
                    <td>{{o.expiry|date:'dd MMM yyyy'}}</td>
                    <td>{{o.subscriptionType}}</td>
                    <td>{{o.paymentType}}</td>
                    <td>{{o.subscriptionStatus}}</td>
                    <td>{{o.comment}}</td>
                    <td>
                        Reporting: {{o.reportingCategoryDisplayName}}
                        <br />
                        ExtraInfo: {{o.extraInfo}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-bar button-bar-modal">
            <lib-button [smLabel]="'Ok'" [smIcon]="'fa fa-check'" [smType]="'submit'" [smClass]="'btn btn-secondary'" (smClick)="ok()"></lib-button>
        </div>
    </div>
</form>

