<div class="form-group row">
  <label class="control-label" [ngClass]="smLabelClass" *ngIf="smLabel"> 
      <span [translate]="smLabel"></span>
      <span *ngIf="smRequired">*</span>
  </label>
  <div [ngClass]="elementClass">
    <ng-container *ngIf="!smSelectWithSearchOption && !smMultiSelectOption">
      <select
              [name]="smName"
              class={{smInputClass}}
              [(ngModel)]="smModel" 
              (ngModelChange)="handleModelChange($event)" 
              [disabled]="smDisabled"
              [tabindex]="smTabindex"
              [ngClass]="{'is-invalid': (ngForm.form.controls[smName] && ngForm.form.controls[smName].errors?.['required']) && ngForm.form.controls[smName].touched}"
              [required]="smRequired">
              <option *ngIf="smPlaceholder" translate={{smPlaceholder}} value="" disabled selected></option>
              <option *ngFor="let item of smOptions" [ngValue]="smOptionValueField? item[smOptionValueField] : item" [selected]="smOptionValueField?item[smOptionValueField] === smModel:item === smModel">{{item[smOptionDisplayField]}}</option>
        </select>
        <span *ngIf="smSubText" class="text-muted" [translate]="smSubText"></span>
      </ng-container>
      <ng-container *ngIf="smMultiSelectOption || smSelectWithSearchOption">
        <!-- [items]="smOptions" -->
        <ng-select  
                    [bindLabel]="smOptionDisplayField"
                    [bindValue]="smOptionValueField"
                    [multiple]="(smSelectWithSearchOption)? false : true"
                    [placeholder]="smPlaceholder"
                    [ngModel]="smModel"
                    [name]="smName"
                    [ngClass]="isSelectSearch?'selectWithSearch':''"
                    (ngModelChange)="handleModelChange($event)"
                    >
                    <ng-option *ngFor="let item of smOptions" [value]="smOptionValueField? item[smOptionValueField] : item" >
                      <img *ngIf="smImageDisplayField" height="15" width="15" [src]="item[smImageDisplayField].toLowerCase()"/>
                      {{item[smOptionDisplayField]}}
                    </ng-option>
        </ng-select>
        </ng-container>
      <!-- <ng-container *ngIf="smSelectWithSearchOption">
        <input class={{smInputClass}} type="text" list="datalistOptions" id="exampleDataList" [(ngModel)]="smModel" (keyup)="handleModelChange($event)" placeholder={{smPlaceholder|translate}}>
        <datalist id="datalistOptions">
          <option *ngIf="smPlaceholder" translate={{smPlaceholder}} value="" disabled selected></option>
          <option *ngFor="let item of smOptions" [ngValue]="smOptionValueField? item[smOptionValueField] : item" [selected]="smOptionValueField?item[smOptionValueField] === smModel:item === smModel">{{item[smOptionDisplayField]}}</option>
        </datalist>
      </ng-container> -->
  </div>
</div>
