import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { SessionService } from './session.service';
import { PortalSessionService } from './portal/session.service';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private authService: AuthService, private sessionService: SessionService, private portalSessionService: PortalSessionService, private dataService: DataService) { }

  verifyToken() {
    if(this.sessionService.accessToken !== undefined) {
      if(this.sessionService.isExpired()) {
        this.authService.logOut();
        return false;
      } else {
        return true;
      } 
    } else {
      if(this.portalSessionService.isExpired()) {
        this.authService.portalLogout();
        return false;
      } else {
        return true;
      } 
    }
  }

  getReport (route: string) {
    if(this.verifyToken()) {
      return this.dataService.getReport(route);
    }
    else {
      return new Promise((resolve, reject) => { 
        reject('');
      });
    }
  }

  getReportWithParams(route: string, params: any) {
    if(this.verifyToken()) {
      return this.dataService.getReportWithParams(route, params);
    }
    else {
      return new Promise((resolve, reject) => { 
        reject('');
      });
    }
  }
}
