<div class="modal-container">
<div class="modal-header">
    <h3 class="modal-title pull-left"><i class="fa fa-times"></i> Change Reporting Category - {{params.company.sanitizedName}}</h3>
    <lib-button [smType]="'button'" (smClick)="cancel()" [smClass]="'btn-close pull-right'" />
</div>
<form class="form-horizontal"  #frm="ngForm"  novalidate>
    <div class="modal-body">
        <div class="row">
            <div class="form-group d-flex" show-errors>
                <label class="control-label col-md-4 d-flex">Subscription Reporting Category</label>
                     <div class="col-md-6">
                          <lib-select-input [smPlaceholder]="'Select Reporting Category'" [smLabelClass]="'col-md-5 disp-cont'" [smName]="'contact'" [(smModel)]="selectedSubscriptionReportingCategory" [smRequired]="true" [smOptionDisplayField]="'displayName'" [smOptions]="subscriptionReportingCategories" [smOptionValueField]="'value'" />
                    </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-bar button-bar-modal">
            <lib-button [smLabel]="'Cancel'" [smType]="'button'" (smClick)="cancel()" [smIcon]="'fa fa-minus'" [smClass]="'btn btn-primary'" />
            <lib-button [smLabel]="'Change Reporting Category'" [smType]="'submit'" (smClick)="ok(frm)" [smIcon]="'fa fa-times'" [smClass]="'btn btn-secondary'" />
        </div>
    </div>
</form>
</div>

