<div class="footer" [ngClass]="{'footer-login':(url==='/login' || url==='/forgotpassword' || url.includes('/completeregistration'))}">
  <p *ngIf="!isAdminSectionEnable" [style]="{color:(url==='/login' || url==='/forgotpassword' ||  url.includes('/completeregistration'))? 'var(--smeazy-copyright-color)' : ''}">
    &copy; <span translate="resources.common-smeasyversion-label-copyright"></span> SMEasy&reg; {{year}}
    <img *ngIf="!isAdminSectionEnable" class="img" [src]="footerPoweredBySmeasyLogoUrl">
  </p>
  <p *ngIf="isAdminSectionEnable" [style]="{color:(url==='/login' || url==='/forgotpassword')? 'var(--smeazy-copyright-color)' : ''}">
    &copy; COPYRIGHT SMEasy &trade; 2015 | Version: {{version}}&nbsp;
    <span *ngIf="hasVersionChanged" class="label label-danger">New Version Available. Please <a class="versionLabelHref" href="#" (click)="refreshApp()">Click Here</a> Or Press Ctrl + F5.&nbsp;</span>
    <span *ngIf="showEnvDetails">
        Environment: {{environment}}, API endpoint: {{endpoint}}
    </span>
  </p>
</div>