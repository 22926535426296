<lib-table 
    [tableColumns]="organisationColumns" 
    [api]="api" 
    [title]="title" 
    [helpLinkURL]="helpLinkUrl"
    [exportButton]="exportButton"
    [pdfButton]="pdfButton"
    [tabData]="tabData" 
    [searchUIOptions]="searchUIOptions"
    [resourceMessages]="resourceMessages"
    (smHandleAddButtonClick)="handleAddButtonClick($event)"
    ></lib-table>
