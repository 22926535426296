<lib-title title="resources.settings-companyprofile-bankingdetail-label-heading">
</lib-title>
<form class="form-horizontal" novalidate (submit)="save(bankingDetail, addHistoricalForm)" #addHistoricalForm="ngForm" >
  <lib-form-panel [smLabel]="'resources.settings-companyprofile-bankingdetail-panelheading-bankingdetail'">
  <div class="row">
    <div class="col-md-6 ">
        <lib-checkbox-input
          [smLabel]="'resources.settings-companyprofile-bankingdetail-label-primarybank'"
          [smLabelClass]="'col-sm-4'"
          [(smModel)]="bankingDetail.isPreferredBank"
          [smDisabled]="bankingDetail.isPreferredBank"
        ></lib-checkbox-input>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <lib-select-input [smLabel]="'resources.settings-companyprofile-bankingdetail-label-bank'"
        [smLabelClass]="'col-sm-4'" [smName]="'bankId'" [(smModel)]="bankingDetail.bankId"
        [smRequired]="true" [smOptionDisplayField]="'value'" [smOptions]="banks" [smDisabled]="bankingDetail.hasTransactions"
        [smOptionValueField]="'key'" [smPlaceholder]="'resources.settings-companyprofile-bankingdetail-bankdropdown-placeholder'"/>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <lib-text-input [smLabel]="'resources.settings-companyprofile-bankingdetail-label-accountname'" [smRequired]="true"
        [smLabelClass]="'col-sm-4 '" [smName]="'accountName'" [smType]="'text'" [(smModel)]="bankingDetail.accountName"
        [smMaxlength]="50" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <lib-text-input [smLabel]="'resources.settings-companyprofile-bankingdetail-label-accountnumber'" [smRequired]="true"
        [smLabelClass]="'col-sm-4 '" [smName]="'accountNo'" [smType]="'text'" [(smModel)]="bankingDetail.accountNo" [smDisabled]="bankingDetailId === 0 ? false : true"
        [smMaxlength]="50" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <lib-select-input [smLabel]="'resources.settings-companyprofile-bankingdetail-label-accountyype'"
        [smLabelClass]="'col-sm-4 '" [smName]="'bankAccountTypeId'" [(smModel)]="bankingDetail.bankAccountTypeId"
        [smRequired]="true" [smOptionDisplayField]="'value'" [smOptions]="bankAccountTypes" [smDisabled]="bankingDetail.hasTransactions"
        [smOptionValueField]="'key'" [smPlaceholder]="'resources.settings-companyprofile-bankingdetail-accoutdropdown-placeholder'"/>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <lib-text-input [smLabel]="'resources.settings-companyprofile-bankingdetail-label-branch'"
        [smLabelClass]="'col-sm-4 '" [smName]="'branch'" [smType]="'text'" [(smModel)]="bankingDetail.branch"
        [smMaxlength]="50" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <lib-text-input [smLabel]="'resources.settings-companyprofile-bankingdetail-label-branchcode'"
        [smLabelClass]="'col-sm-4 '" [smName]="'branchCode'" [smType]="'text'" [(smModel)]="bankingDetail.branchCode"
        [smMaxlength]="50" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <lib-text-input [smLabel]="'resources.settings-companyprofile-bankingdetail-label-swiftcode'"
        [smLabelClass]="'col-sm-4 '" [smName]="'swiftCode'" [smType]="'text'" [(smModel)]="bankingDetail.swiftCode"
        [smMaxlength]="50" />
    </div>
  </div>
  </lib-form-panel>
  <div class="button-bar">
      <lib-validation-message></lib-validation-message>
      <lib-button [smLabel]="'resources.common-buttons-save'" [smType]="'submit'" [smDisabled]="saveButton" [smIcon]="'fa fa-save'" [smClass]="'btn btn-primary btnPrimaryAction'" />
  </div>
</form>