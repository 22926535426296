import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-static-text',
  templateUrl: './static-text.component.html',
  styleUrls: ['./static-text.component.scss']
})
export class StaticTextComponent {
  @Input() smLabel = '';
  @Input() smLabelClass=''; 
  @Input() smStrong = false;
  @Input() smValue: any='';
  @Input() smElementClass = '';
  @Input() smType = '';
  @Input()
  set smModel(smModel: any) {

    if ((this.smType==="decimal") && (smModel != null && smModel != undefined)){
        this.smValue=Number(smModel).toLocaleString('en-US',this.decimalOptions);
    }
    else 
      this.smValue = smModel;
  }
  private decimalOptions = {
    minimumFractionDigits : 2,
    maximumFractionDigits : 2
  }
  elementClass='';

  ngOnInit(){

    if(!this.smLabelClass.includes('col-md'))
    {
      this.smLabelClass += "col-md-3"
    }
    const colIdx = this.smLabelClass.indexOf("col-md-")
    this.elementClass='col-md-' + (12 - parseInt(this.smLabelClass.substring(colIdx+7,colIdx+9)));

  }
}
