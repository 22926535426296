import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "web-insufficient-permissions",
  templateUrl: "./insufficient-permissions.component.html",
  styleUrls: ["./insufficient-permissions.component.scss"],
})
export class InsufficientPermissionsComponent{
  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.fragment.subscribe((params) => {
     this.screenName = params;
    });
  }

  screenName: string|unknown = '';
}
