<div class="mt-3">
  <lib-table
    [tableColumns]="queryColumns"
    [api]="api"
    [title]="title"
    [exportButton]="exportButton"
    [addNewButton]="addNewButton"
    [refreshButton]="refreshButton"
    [pdfButton]="pdfButton"
    [searchUIOptions]="searchUIOptions"
    [helpLinkURL]="helpLinkURL"
    [resourceMessages]="resourceMessages"
  >
  </lib-table>
</div>
