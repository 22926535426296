import { Component, Input, OnInit } from "@angular/core";
import { Router, NavigationEnd } from '@angular/router';
import { webConstants } from '../../../constants/web.constants';
import { portal_env } from 'packages/shared-lib/src/environments/environment';

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
  @Input() isAdminSectionEnable = false;

  url="";

  version = '';
  environment = '';
  endpoint = '';
  showEnvDetails = false;
  footerPoweredBySmeasyLogoUrl = ""
  hasVersionChanged = false;
  year = new Date().getFullYear();

  constructor(private router: Router) {
    router.events.subscribe((event:any) => {
      if(event instanceof NavigationEnd)
        this.url=event.urlAfterRedirects
    })
  }

  ngOnInit() {
    this.version = this.isAdminSectionEnable ? portal_env.version : webConstants.currentEnv.version;
    this.environment = this.isAdminSectionEnable ? portal_env.environment : webConstants.currentEnv.environment;
    this.endpoint = this.isAdminSectionEnable ? portal_env.serverBaseUrl : webConstants.currentEnv.serverBaseUrl;
    this.showEnvDetails = this.isAdminSectionEnable ? portal_env.showEnvDetails : webConstants.currentEnv.showEnvDetails;
    this.footerPoweredBySmeasyLogoUrl = webConstants.currentEnv.footerPoweredBySmeasyLogoUrl;
  }

  refreshApp() {
    location.reload();
  }
}
