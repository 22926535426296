<div class="modal-header">
    <h3 class="modal-title pull-left"><i class="fa fa-credit-card"></i> Add New Bulk Licenses for {{formData.title}}</h3>
    <lib-button [smType]="'button'" (smClick)="closeModal()" [smClass]="'btn btn-close pull-right'" />
</div>
<form class="form-horizontal" #frm="ngForm" novalidate>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-10"><lib-text-input [smLabel]="'Number of Vouchers'" [smLabelClass]="'col-md-4'"
                    [smName]="'numberofvouchers'" [smType]="'text'" [(smModel)]="formData.numberofvouchers"
                    [smRequired]="true" /></div>
        </div>
        <div class="row">
            <div class="col-md-10"><lib-text-input [smLabel]="'Days voucher will be valid for'"
                    [smLabelClass]="'col-md-4'" [smName]="'daysValidFor'" [smType]="'text'"
                    [(smModel)]=" formData.daysValidFor" [smRequired]="true"
                    [smSubText]="'This is the # of days the voucher is valid for AFTER being redeemed.'" /></div>
        </div>
        <div class="row">
            <div class="col-md-10"><lib-select-input [smOptionDisplayField]="'value'" [smOptionValueField]="'id'"
                    [smLabel]="'Redemption Days valid for'" [smLabelClass]="'col-md-4'"
                    [smName]="'redemptionDaysValidFor'" [(smModel)]="formData.redemptionDaysValidFor"
                    [smRequired]="true" [smOptions]="redemptionDaysValidFor"
                    [smSubText]="'This is the # of days the voucher is redeemable for.'" /></div>
        </div>
        <div class="row">
            <div class="col-md-10"><lib-date-picker [smLabel]="'Redemption Start Date'" [smLabelClass]="'col-md-4'"
                    [smName]="'redemptionStartDateDP'" [(smModel)]="formData.redemptionStartDateDP" [smDisabled]="false"
                    [smSubText]="'This is the date from which the voucher is redeemable.'" /></div>
        </div>
        <div class="row">
            <div class="col-md-10"><lib-text-input [smLabel]="'Invoice Number'" [smLabelClass]="'col-md-4'"
                    [smName]="'invoicenumber'" [smType]="'text'" [(smModel)]="formData.invoicenumber"
                    [smRequired]="true" /></div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-bar button-bar-modal">
            <lib-button [smLabel]="'Cancel'" [smType]="'button'" (smClick)="closeModal()" [smIcon]="'fa fa-minus'"
                [smClass]="'btn btn-primary'" />
            <lib-button [smLabel]="'Save'" [smType]="'submit'" (smClick)="save(frm)" [smIcon]="'fa fa-save'"
                [smClass]="'btn btn-secondary'" />
        </div>
    </div>
</form>