import { Component, EventEmitter, Input, OnInit, Optional, Output } from "@angular/core";
import { ControlContainer, NgForm} from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "lib-select-input",
  templateUrl: "./select-input.component.html",
  styleUrls: ["./select-input.component.scss"],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class SelectInputComponent implements OnInit{

  @Input() smOptions:any[]=[]; 
  @Input() smOptionDisplayField=''; 
  @Input() smOptionValueField=''; 
  @Input() smSelectWithSearchOption=false;
  @Input() smMultiSelectOption=false;
  @Input() smLabel=''; 
  @Input() smInputClass = 'form-select';
  @Input() smLabelClass=''; 
  @Input() smRequired=false;
  @Input() smPlaceholder='';
  @Input() smDisabled = false;
  @Input() smModel:any = '';
  @Input() smName = '';
  @Input() smTabindex = 0;
  @Input() smImageDisplayField: string|undefined = '';
  @Input() smSubText = '';
  @Input() isSelectSearch = false;
  @Output() smModelChange = new EventEmitter<string>();

  elementClass='';

  constructor(@Optional() public ngForm: NgForm, private translateService:TranslateService) {}

  ngOnInit(){
    if(this.smLabel)
    {
      if(!this.smLabelClass.includes('col-md'))
      {
        this.smLabelClass += " col-md-3"
      }
      const colIdx = this.smLabelClass.indexOf("col-md-")
      this.elementClass='col-md-' + (12 - parseInt(this.smLabelClass.substring(colIdx+7,colIdx+9)));
    } 
    this.initTranslation()
  }
  initTranslation() {
    if (this.smPlaceholder !== '') {     
      this.translateService.get(this.smPlaceholder).subscribe((msg:string)=> {
          this.smPlaceholder = msg;
      });
    }
    
  }
  handleModelChange(e: any) {
    // eslint-disable-next-line no-empty
    if(this.smSelectWithSearchOption){
     
    } 
    this.smModelChange.emit(e);
  }
  

}
