import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/login/forgot-password.component';
import { authGuard } from 'packages/shared-lib/src/lib/services/route-guards/auth-guard'
import { CompanyProfileComponent } from './pages/settings/company-profile/company-profile.component';
import { OrganisationDetailsComponent } from './pages/contacts/organisation-details/organisation-details.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { FinanceComponent } from './pages/finance/finance.component';
import { QuotesComponent } from './pages/finance/quotes/quotes.component';
import { QuotesPageComponent } from './pages/finance/quotes/quotes-page/quotes.component';
import { QuoteComponent } from './pages/finance/quotes/quotes-page/quote/quote.component';
import { ImportComponent } from './pages/settings/import/import.component';
import { ImportContactsComponent } from './pages/settings/import/import-contacts/import-contacts.component';
import { ImportHistoricalBankComponent } from './pages/settings/import/import-historical-bank/import-historical-bank.component';
import { ImportHistoricalCustomerComponent } from './pages/settings/import/import-historical-customer/import-historical-customer.component';
import { ImportAllCustomersComponent } from './pages/settings/import/import-contacts/import-all-customers/import-all-customers.component';
import { ImportDetailContactsComponent } from './pages/settings/import/import-contacts/import-detail-contacts/import-detail-contacts.component';
import { BankingdetailsComponent } from './pages/settings/bankingdetails/bankingdetails.component';
import { UserDetailsComponent } from './pages/settings/users/user-details/user-details.component';
import { StaffdetailsComponent } from './pages/staff/staffdetails/staffdetails.component';
import { StaffComponent } from './pages/staff/staff.component';
import { OrganisationContactComponent } from './pages/contacts/organisation-contacts/organisation-contact/organisation-contact.component';
import { TaxInformationComponent } from './pages/tax-information/tax-information.component';
import { DeregisterFromVatComponent } from './pages/tax-information/deregister-from-vat/deregister-from-vat.component';
import { RegisterForVatComponent } from './pages/tax-information/register-for-vat/register-for-vat.component';
import { AllocateComponent } from './pages/finance/bank-accounts/allocate/allocate.component';

import { StaffdetailComponent } from './pages/staff/staffdetail/staffdetail.component';
import { SalesLeadComponent } from './pages/contacts/sales-leads/sales-lead/sales-lead.component';
import { SalesLeadsComponent } from './pages/contacts/sales-leads/sales-leads.component';
import { UserComponent } from './pages/settings/users/user/user.component';
import { OrganisationContactsComponent } from './pages/contacts/organisation-contacts/organisation-contacts.component';
import { InvoicingComponent } from './pages/finance/invoicing/invoicing.component';
import { PriceListComponent } from './pages/finance/invoicing/price list/price-list.component';
import { AddHistoricalBankComponent } from './pages/settings/import/import-historical-bank/add-historical-bank/add-historical-bank.component';
import { OrganisationComponent } from './pages/contacts/organisation-details/organisation/organisation.component';
import { PrefixandstartingnumbersettingsComponent } from './pages/settings/prefixandstartingnumbersettings/prefixandstartingnumbersettings.component';
import { SupplierInvoicesComponent } from './pages/finance/invoicing/supplier-invoices/supplier-invoices.component';
import { SupplierInvoiceComponent } from './pages/finance/invoicing/supplier-invoices/supplier-invoice/supplier-invoice.component';
import { OwnersmoneyComponent } from './pages/finance/ownersmoney/ownersmoney.component';
import { PriceListItemComponent } from './pages/finance/quotes/price list item/price-list-item.component';
import { CompanySalaryScheduleComponent } from './pages/staff/company-salary-schedule/company-salary-schedule.component';
import { ImportBankStatementComponent } from './pages/finance/bank-accounts/import-bank-statement/import-bank-statement.component';
import { BusinessCashComponent } from './pages/finance/business-cash/business-cash.component';
import { AddBusinessCashComponent } from './pages/finance/business-cash/add-business-cash/add-business-cash.component';
import { CurrencyComponent } from './pages/settings/currency/currency.component';
import { BankAccountsComponent } from './pages/finance/bank-accounts/bank-accounts.component';
import { BankStatementsByMonthComponent } from './pages/finance/bank-accounts/bank-statements/bank-statements-by-month/bank-statements-by-month.component';
import { BankStatementsByUploadComponent } from './pages/finance/bank-accounts/bank-statements/bank-statements-by-upload/bank-statements-by-upload.component';
import { PayslipsComponent } from './pages/staff/payslips/payslips.component';
import { PayslipComponent } from './pages/staff/payslips/payslip/payslip.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { IndividualSalaryScheduleComponent } from './pages/staff/individual-salary-schedule/individual-salary-schedule.component';
import { CustomerAgeAnalysisComponent } from './pages/reports/customer-age-analysis/customer-age-analysis.component';
import { CustomLedgerAccountsComponent } from './pages/accountant/custom-ledger-accounts/custom-ledger-accounts.component';
import { CustomerStatementsComponent } from './pages/reports/customer-statements/customer-statements.component';
import { CashFlowComponent } from './pages/reports/cash-flow/cash-flow.component';
import { UserPermissionComponent } from './pages/settings/users/user-permission/user-permission.component';
import { BankStatementAllocatedItemsComponent } from './pages/finance/bank-accounts/bank-statements/bank-statement-allocated-items/bank-statement-allocated-items.component';
import { BusinessLoansComponent } from './pages/finance/business-loans/business-loans.component';
import { CustomLedgerAccountComponent } from './pages/accountant/custom-ledger-account/custom-ledger-account.component';
import { AllocateDetailsComponent } from './pages/finance/bank-accounts/allocate/allocate-details/allocate-details.component';
import { QueryComponent } from './pages/accountant/query/query.component';
import { CreditnotesComponent } from './pages/finance/credit-note/creditnotes/creditnotes.component';
import { CustomerInvoicesComponent } from './pages/finance/invoicing/customer-invoices/customer-invoices.component';
import { CustomerInvoiceComponent } from './pages/finance/invoicing/customer-invoices/customer-invoice/customer-invoice.component';
import { CreditNoteComponent } from './pages/finance/credit-note/credit-note/credit-note.component';
import { PackagedetailsComponent } from './pages/staff/packagedetails/packagedetails.component';
import { SupplierInvoicesOwedComponent } from './pages/reports/supplier-invoices-owed/supplier-invoices-owed.component';
import { SupplierAgeAnalysisComponent } from './pages/reports/supplier-age-analysis/supplier-age-analysis.component';
import { AdjustmentsComponent } from './pages/accountant/adjustment/adjustments/adjustments.component';
import { AdjustmentComponent } from './pages/accountant/adjustment/adjustment/adjustment.component';
import { VatComponent } from './pages/reports/vat/vat.component';
import { CustomerInvoiceDueByDateComponent } from './pages/reports/customer-invoice-due-by-date/customer-invoice-due-by-date.component';
import { AccountantComponent } from './pages/accountant/accountant.component';
import { SetupComponent } from './pages/accountant/setup/setup.component';
import { ExportDataComponent } from './pages/accountant/export-data/export-data.component';
import { BalancesheetComponent } from './pages/accountant/balancesheet/balancesheet.component';
import { TrialBalanceComponent } from './pages/accountant/trial-balance/trial-balance.component';
import { AllTransactionsPerCustomerComponent } from './pages/reports/all-transactions-per-customer/all-transactions-per-customer.component';
import { IncomeStatementComponent } from './pages/accountant/income-statement/income-statement.component';
import { GeneralLedgerComponent } from './pages/accountant/general-ledger/general-ledger.component';
import { OrganisationContactByOrganisationComponent } from './pages/contacts/organisation-details/organisation/organisation-contact-by-organisation/organisation-contact-by-organisation.component';
import { OrganisationCustomerProjectsComponent } from './pages/contacts/organisation-details/organisation/organisation-customer-projects/organisation-customer-projects.component';
import { OrganisationCustomerInvoicesComponent } from './pages/contacts/organisation-details/organisation/organisation-customer-invoices/organisation-customer-invoices.component';
import { OrganisationProjectComponent } from './pages/contacts/organisation-details/organisation/organisation-customer-projects/organisation-project/organisation-project.component';
import { InsufficientPermissionsComponent } from './pages/insufficientPermissions/insufficient-permissions.component';
import { webConstants } from 'packages/shared-lib/src/lib/constants/web.constants';
import { PageNotFoundComponent } from 'packages/shared-lib/src/lib/ui/components/page-not-found/page-not-found.component';
import { CompleteRegistrationComponent } from './pages/login/completeRegistration/complete-registration.component';
import { CompanyProfileWizardComponent } from './pages/login/companyProfileWizard/company-profile-wizard.component';
import { SsoComponent } from './pages/sso/sso.component';

const permissions = webConstants.permissions;
const appTitle = webConstants.currentEnv.appTitle;

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full', title: appTitle+': login'},
  { path: 'ssoauth', component:  SsoComponent, title: appTitle+': sso'},
  { path: 'login', component:  LoginComponent, title: appTitle+': login'},
  { path: 'forgotpassword', component:  ForgotPasswordComponent, title: appTitle+': forgotpassword'},
  { path: 'completeregistration', component:  CompleteRegistrationComponent, title: appTitle+': completeregistration'},
  { path: 'completeregistration/:id', component:  CompleteRegistrationComponent, title: appTitle+': completeregistration'},
  { path: 'companyprofilewizard', component:  CompanyProfileWizardComponent, canActivate:[authGuard], title: appTitle+': companyprofilewizard'},
  /**
   * Dashboard
   */
  { path: 'dashboard', component:  DashboardComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessDashboard}, title: appTitle+': dashboard'},
  /**
   * Settings 
   */
  { path: 'settings', component:  SettingsComponent, canActivate:[authGuard], title: appTitle+': settings'},
  { path: 'settings/companyprofile', component:  CompanyProfileComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsCompanyProfile, title: 'company profile'}, title: appTitle+': company profile'},
  { path: 'settings/currency', component:  CurrencyComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsCompanyProfile,  title: 'currency'}, title: appTitle+': currency'},
  { path: 'settings/prefixandstartingnumbersettings', component:  PrefixandstartingnumbersettingsComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsCompanyProfile, title: 'prefix and starting number settings'}, title: appTitle+': prefix and starting number settings'},
  { path: 'settings/bankingdetails', component:  BankingdetailsComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsCompanyProfile, title: 'banking details'}, title: appTitle+': banking details'},
  { path: 'settings/bankingdetails/bankingdetail/:id', component:  AddHistoricalBankComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsCompanyProfile, title: 'banking details'}, title: appTitle+': banking details'},
  { path: 'settings/taxinformation', component: TaxInformationComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessSettingsCompanyProfile, title: 'tax information'}, title: appTitle+': tax information' },
  { path: 'settings/import', component:  ImportComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsImport, title: 'import dashboard'}, title: appTitle+': import dashboard'},
  { path: 'settings/import/import', component:  ImportContactsComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsImportContactsCsv, title: 'import contatcs dashboard'}, title: appTitle+': settings'},
  { path: 'settings/import/imports/bankstatement', component:  ImportHistoricalBankComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsImportHistoricalBankStatement, title: 'bankstatement'}, title: appTitle+': bankstatement'},
  { path: 'settings/import/imports/customerinvoice', component:  ImportHistoricalCustomerComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsImportHistoricalCustomerInvoice, title: 'customerinvoice'}, title: appTitle+': customerinvoice'},
  { path: 'settings/import/import/organisations', component:  ImportAllCustomersComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsImportContactsCsv, title: 'organisations'}, title: appTitle+': organisations'},
  { path: 'settings/import/imports/organisationcontacts', component:  ImportDetailContactsComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsImportContactsCsv, title: 'organisation contacts'}, title: appTitle+': organisation contacts'},
  { path: 'settings/users', component:  UserDetailsComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsSystemAccess, title: 'users'}, title: appTitle+': users'},
  { path: 'settings/users/:id', component: UserComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsSystemAccess, title: 'user'}, title: appTitle+': user'},
  { path: 'settings/users/:id/permissions', component: UserPermissionComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessSettingsSystemAccess, title: 'permissions'}, title: appTitle+': permissions'},
  { path: 'settings/companyprofile/deregisterfromvat', component: DeregisterFromVatComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessSettingVatRegistration, title: 'deregister from vat'}, title: appTitle+': deregister from vat' },
  { path: 'settings/companyprofile/registerforvat', component: RegisterForVatComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessSettingVatRegistration, title: 'register for vat'}, title: appTitle+': register for vat'},
  /**
   * Contacts
   */
  { path: 'contacts', component: ContactsComponent, canActivate:[authGuard], title: appTitle+': contacts'},
  { path: 'contacts/organisations', component: OrganisationDetailsComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessContactsCompany, title: 'organisations'}, title: appTitle+': organisations'},
  { path: 'contacts/organisations/:id', component: OrganisationComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessContactsCompany,  title: 'new organisation'}, title: appTitle+': new organisation'},
  { path: 'contacts/organisations/:id/contacts', component: OrganisationContactByOrganisationComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessContactsCompany, title: 'contacts'}, title: appTitle+': contacts'},
  { path: 'contacts/organisations/:id/projects', component: OrganisationCustomerProjectsComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessContactsCompany, title: 'projects'}, title: appTitle+': projects'},
  { path: 'contacts/organisations/:id/invoices', component: OrganisationCustomerInvoicesComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessContactsCompany, title: 'invoices'}, title: appTitle+': invoices'},
  { path: 'contacts/organisations/:organisationId/contacts/:id', component: OrganisationContactComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessContactsCompany, title: 'organisations'}, title: appTitle+': organisations'},
  { path: 'contacts/organisations/:contactId/projects/:id', component: OrganisationProjectComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessContactsCompany, title: 'projects'}, title: appTitle+': projects'},
  { path: 'contacts/organisationContacts', component: OrganisationContactsComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessContactsIndividual, title: 'organisation contacts'}, title: appTitle+': organisation contacts' },
  { path: 'contacts/organisationContacts/:id', component:  OrganisationContactComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessContactsIndividual, title: 'organisation contact'}, title: appTitle+': organisation contact'},
  { path: 'contacts/salesleads',component: SalesLeadsComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessContactsIndividual, title: 'sales leads'}, title: appTitle+': sales leads'},
  { path: 'contacts/salesleads/:id', component:  SalesLeadComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessContactsIndividual, title: 'sales lead'}, title: appTitle+': sales lead'},
  /**
   * Staff
   */
  { path: 'staff', component: StaffComponent, canActivate:[authGuard], title: appTitle+': staff'},
  { path: 'staff/staffdetails', component: StaffdetailsComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessStaffStaffDetails, title: 'staff details'}, title: appTitle+': staff details'},
  { path: 'staff/staffdetails/:id', component: StaffdetailComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessStaffStaffDetails, title: 'staff details'}, title: appTitle+': staff details'},
  { path: 'staff/staffdetails/:id/packagedetails', component: PackagedetailsComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessStaffPayslip, title: 'staff details'}, title: appTitle+': staff details'},
  { path: 'staff/payslips', component: PayslipsComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessStaffPayslip, title: 'payslips'}, title: appTitle+': payslips'},
  { path: 'staff/payslips/:id', component: PayslipComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessStaffPayslip, title: 'payslip'}, title: appTitle+': payslip'},
  { path: 'staff/companysalaryschedule', component: CompanySalaryScheduleComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessStaffSalarySchedule, title: 'company salary schedule'}, title: appTitle+': company salary schedule'},
  { path: 'staff/individualsalaryschedule', component: IndividualSalaryScheduleComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessStaffSalarySchedule, title: 'individual salary schedule'}, title: appTitle+': individual salary schedule' },
  /**
   * Finance
   */
  { path: 'finance', component: FinanceComponent, canActivate:[authGuard], title: appTitle+': finance'},
  { path: 'finance/businessloans', component: BusinessLoansComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessFinanceBusinessLoans, title: 'business loans'}, title: appTitle+': business loans'},
  { path: 'finance/ownersmoney', component: OwnersmoneyComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessFinanceOwnersMoney, title: 'owner\'s money'}, title: appTitle+': owner\'s money'},
  { path: 'finance/quotes', component: QuotesComponent, canActivate:[authGuard], title: appTitle+': quotes'},
  { path: 'finance/quotes/quotes', component: QuotesPageComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessFinanceQuotes, title: 'quotes'}, title: appTitle+': quotes'},
  { path: 'finance/quotes/quotes/:id', component: QuoteComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessFinanceQuotes, title: 'quote'}, title: appTitle+': quote'},
  { path: 'finance/quotes/pricelist', component: PriceListComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessFinanceInvoicing, title: 'price list'}, title: appTitle+': price list'},
  { path: 'finance/quotes/pricelist/:id', component: PriceListItemComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessFinanceInvoicing, title: 'add product'}, title: appTitle+': add product'},
  { path: 'finance/bankaccounts', component: BankAccountsComponent, canActivate:[authGuard], title: appTitle+': bankaccounts'},
  { path: 'finance/bankaccounts/allocate', component: AllocateComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessFinanceBankAccounts, title: 'allocate bank statements'}, title: appTitle+': allocate bank statements'},
  { path: 'finance/bankaccounts/allocate/:id/:accountId', component: AllocateDetailsComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessFinanceBankAccounts, title: 'allocate bank statement'}, title: appTitle+': allocate bank statement'},
  { path: 'finance/bankaccounts/allocate/:bankStatementId/:ledgerAccountId', component: AllocateComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceBankAccounts, title: 'allocate bank statement'}, title: appTitle+': allocate bank statement'},
  { path: 'finance/bankaccounts/bankstatementsbymonth', component: BankStatementsByMonthComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceBankAccounts, title: 'bank statements'}, title: appTitle+': bank statements'},
  { path: 'finance/bankaccounts/bankstatementsbymonth/:id/:year/:month', component: BankStatementAllocatedItemsComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceBankAccounts, title: 'bank statement'}, title: appTitle+': bank statement'},
  { path: 'finance/bankaccounts/bankstatementsbyupload', component: BankStatementsByUploadComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceBankAccounts, title: 'bank statements'}, title: appTitle+': bank statements'},
  { path: 'finance/bankaccounts/bankstatementsbyupload/:id', component: BankStatementAllocatedItemsComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceBankAccounts, title: 'bank statement'}, title: appTitle+': bank statement'},
  { path: 'finance/bankaccounts/import', component:  ImportBankStatementComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessFinanceBankAccounts, title: 'import bank statements'}, title: appTitle+': import bank statements'},
  { path: 'finance/invoicing', component:  InvoicingComponent, canActivate:[authGuard], title: appTitle+': invoicing'},
  { path: 'finance/invoicing/pricelist', component:  PriceListComponent, canActivate:[authGuard], data: {permission:permissions.CanAccessFinanceInvoicing, title: 'price list'}, title: appTitle+': price list'},
  { path: 'finance/invoicing/supplierinvoices', component: SupplierInvoicesComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceInvoiceIn, title: 'supplier invoices'}, title: appTitle+': supplier invoices'},
  { path: 'finance/invoicing/supplierinvoices/:id', component: SupplierInvoiceComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceInvoiceIn, title: 'supplier invoice'}, title: appTitle+': supplier invoice'},
  { path: 'finance/invoicing/customerinvoices', component: CustomerInvoicesComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceInvoiceOut, title: 'customer invoices'}, title: appTitle+': customer invoices'},
  { path: 'finance/invoicing/customerinvoices/:id', component: CustomerInvoiceComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceInvoiceOut,  title: 'customer invoice'}, title: appTitle+': customer invoice'},
  { path: 'finance/invoicing/creditnotes', component: CreditnotesComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessFinanceCreditNote, title: 'credit notes'}, title: appTitle+': credit notes'},
  { path: 'finance/invoicing/creditnotes/:creditNoteId/customerinvoice/:invoiceOutId', component:  CreditNoteComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessFinanceCreditNote, title: 'credit note'}, title: appTitle+': credit note'},
  { path: 'finance/businesscash', component: BusinessCashComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceBusinessCash, title: 'business cash'}, title: appTitle+': business cash'},
  { path: 'finance/businesscash/addbusinesscash', component: AddBusinessCashComponent, canActivate: [authGuard], data: {permission:permissions.CanAccessFinanceBusinessCash, title: 'add buinsess cash'}, title: appTitle+': add buinsess cash'},
  /**
   * Reports
   */
  { path: 'reports', component: ReportsComponent, canActivate:[authGuard], title: appTitle+': reports'},
  { path: 'reports/cashflow', component: CashFlowComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessReportsCashGauge, title: 'cashflow'}, title: appTitle+': cashflow'},
  { path: 'reports/vat', component: VatComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessReportsVat, title: 'vat'}, title: appTitle+': vat'},
  { path: 'reports/customerageanalysis',component: CustomerAgeAnalysisComponent,canActivate:[authGuard],data: {permission:permissions.CanAccessReportsWhoOwesUs, title: 'per customer'}, title: appTitle+': per customer'},
  { path: 'reports/customerstatementbycustomer', component: CustomerStatementsComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessReportsWhoOwesUs, title: 'per customer'}, title: appTitle+': per customer'},
  { path: 'reports/customerinvoicesdue',component: CustomerInvoiceDueByDateComponent,canActivate:[authGuard],data: {permission:permissions.CanAccessReportsWhoOwesUs, title: 'per customer'}, title: appTitle+': per customer'},
  { path: 'reports/customerinvoicesduebycustomer', component: AllTransactionsPerCustomerComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessReportsWhoOwesUs, title: 'per customer'}, title: appTitle+': per customer'},
  { path: 'reports/supplierinvoicesowed', component: SupplierInvoicesOwedComponent, canActivate: [authGuard],data: {permission:permissions.CanAccessReportsWhoWeOwe, title: 'supplier invoices owed'}, title: appTitle+': supplier invoices owed' },
  { path: 'reports/supplierageanalysis', component: SupplierAgeAnalysisComponent, canActivate: [authGuard],data: {permission:permissions.CanAccessReportsWhoWeOwe, title: 'supplier age analysis'}, title: appTitle+': supplier age analysis' },
 
  { path: 'accountant',component: AccountantComponent,canActivate:[authGuard], title: appTitle+': accountant'},
  { path: 'accountant/trialbalance', component: TrialBalanceComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessAccountantTrailBalance, title: 'trial balance'}, title: appTitle+': trial balance'},
  { path: 'accountant/generalledger', component: GeneralLedgerComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessAccountantGeneralLedger, title: 'general ledger'}, title: appTitle+': general ledger'},
  { path: 'accountant/customledgeraccounts', component: CustomLedgerAccountsComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessAccountantCustomLedgerAccounts, title: 'customledgeraccounts'}, title: appTitle+': customledgeraccounts'},
  { path: 'accountant/customledgeraccounts/add', component: CustomLedgerAccountComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessAccountantCustomLedgerAccounts, title: 'customledgeraccounts'}, title: appTitle+': customledgeraccounts'},
  { path: 'accountant/query', component: QueryComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessAccountantAskMyAccountant, title: 'query'}, title: appTitle+': query'},
  { path: 'accountant/exportdata', component: ExportDataComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessAccountantExportData, title: 'export data'}, title: appTitle+': export data'},
  { path: 'accountant/incomestatement', component: IncomeStatementComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessAccountantIncomeStatement, title: 'income statement'}, title: appTitle+': income statement'},
  { path: 'accountant/adjustments', component: AdjustmentsComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessAccountantAdjustments, title: 'adjustments'}, title: appTitle+': adjustments'},
  { path: 'accountant/adjustments/add', component: AdjustmentComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessAccountantAdjustments, title: 'adjustments'}, title: appTitle+': adjustments'},
  { path: 'accountant/balancesheet', component: BalancesheetComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessAccountantBalanceSheet, title: 'balance sheet'}, title: appTitle+': balance sheet'},
  { path: 'accountant/setup', component: SetupComponent, canActivate:[authGuard],data: {permission:permissions.CanAccessSettingsSetup, title: 'setup'}, title: appTitle+': setup'},
  /**
   * Insufficient permission - when there is no permission of page it will redirect to /insufficientpermissions
   */
  { path: 'insufficientpermissions', component: InsufficientPermissionsComponent, canActivate:[authGuard], title: appTitle+': Insufficient Permissions'},
  { path: '**', component: PageNotFoundComponent, canActivate:[authGuard]},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
