import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { webConstants } from '../constants/web.constants';
const baseUrl = webConstants.currentEnv.serverBaseUrl;


@Injectable({
  providedIn: 'root'
})
export class CompleteRegistrationService {
  constructor(private dataService : DataService) { }

  getCompanyDetailsForOtp:any = (completeRegistrationToken:any) => {
    const url = baseUrl + '/api/registration/otp/companydetails/' + completeRegistrationToken;
    return this.dataService.get(url);
  }

  requestOtp:any = (payload:any) => {
      return this.dataService.post( baseUrl +'/api/company/otp', payload)
  }

  completeRegistration:any = (payload:any) => {
      return this.dataService.post( baseUrl +'/api/registration/otp/complete', payload);
  }
}
