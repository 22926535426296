<div ngClass="{'danger': includeDangerHeader}" class="modal-header">
    <h3 class="modal-title pull-left">
        <i class="fa fa-exclamation-triangle"></i>
        <span class="nameSpace"> {{modalInstance.title}}</span>
    </h3>
     <lib-button
    [smTitle]="'button'"
    [smClass]="'btn btn-close pull-right'"
    (smClick)="closeModal()"
  />
</div>
<form class="form-horizontal" #frm="ngForm" novalidate>
    <div class="modal-body">
        <div class="row">
            <div class="form-group">
                <div class="col-md-1">
                </div>
                <div class="col-md-11">
                    <p>{{modalInstance.message}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group">
                <div class="col-md-1">
                </div>
                <div class="col-md-6">
                    <lib-text-input
                    [smType]="'password'"
                    [(smModel)]="password"
                    [smLabelClass]="'form-control'"
                    [smName]="'password'"
                    
                    ></lib-text-input>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-bar ">
             <lib-button
      [smLabel]="'resources.modal-buttons-cancel'"
      [smType]="'button'"
      [smClass]="'btn btnSecondaryAction btn-quaternary'"
      (smClick)="closeModal()"
    />
     <lib-button
      [smLabel]="'resources.modal-buttons-continue'"
      [smType]="'submit'"
      [smClass]="'btn btnSecondaryAction btn-secondary'"
      (smClick)="passBack(password)"
    />
        </div>
    </div>
</form>
