<lib-table
  [tableColumns]="organisationColumns"
  [api]="api"
  [title]="title"
  [exportButton]="exportButton"
  [tabData]="tabData"
  [searchUIOptions]="searchUIOptions"
  [helpLinkURL]="helpLinkURL"
  [resourceMessages]="resourceMessages"
  (smHandleAddButtonClick)="handleAddButtonClick($event)"
  (smHandleUpdateButtonClick)="handleUpdateButtonClick($event)"
></lib-table>
