<div class="modal-header">
    <h3 class="modal-title pull-left">
        <i class="fa fa-plus"></i>
        <span class="nameSpace" translate="resources.finance-businessloans-heading-businessloanaccountitem"></span>
    </h3>

    <lib-button [smType]="'button'" (smClick)="closeModal()" [smClass]="'btn btn-close pull-right'" />
</div>
<div class="modal-body">
    <form class="form-horizontal" #frm="ngForm" novalidate>
        <lib-form-panel>
            <div class="row">
                <lib-date-picker [smPlaceholder]="datePlaceHolder"
                    [smLabel]="'resources.finance-businessloans-label-date'" [smLabelClass]="'col-md-3'"
                    [smName]="'createdDate'" [(smModel)]="item.createdDate" [smRequired]="true" [smDisabled]="false"
                    [smShowWeeks]="false" />
            </div>
            <div class="row">
                <lib-select-input [smPlaceholder]="'resources.common-dropdown-selectplaceholder'"
                    [smLabel]="'resources.finance-businessloans-label-category'" [smLabelClass]="'col-md-3'"
                    [smName]="'selectedLedgerAccount'" [(smModel)]="item.selectedLedgerAccount"
                    (smModelChange)="ledgerAccountChange(item.selectedLedgerAccount)" [smRequired]="true"
                    [smOptionDisplayField]="'displayName'" [smOptions]="ledgerAccounts" />
            </div>
            <div class="row">
                <lib-textarea-input [smLabel]="'resources.finance-businessloans-label-description'"
                    [smLabelClass]="'col-md-3'" [smName]="'description'" [(smModel)]="item.description"
                    [smMaxlength]="100" [smRows]="2" [smRequired]="true" />
            </div>
            <div class="row">
                <lib-text-input [smLabel]="'resources.finance-businessloans-label-reference'"
                    [smLabelClass]="'col-md-3'" [smName]="'reference'" [(smModel)]="item.reference" [smMaxlength]="100"
                    [smRows]="2" [smRequired]="true" />
            </div>
            <div class="row" *ngIf="isLedgerAccountVatable != null ? isLedgerAccountVatable : params.showVat">
                <lib-text-input [smLabel]="'resources.finance-businessloans-label-exclusive'"
                    [smLabelClass]="'col-md-3'" [smName]="'exclusiveAmount'" [smType]="'number'" [smMaxlength]="50"
                    [(smModel)]="item.exclusiveAmount" [smDisabled]="true" />
            </div>

            <div class="row" *ngIf="isLedgerAccountVatable != null ? isLedgerAccountVatable : params.showVat">
                <lib-text-input [smLabel]="'resources.finance-businessloans-label-vat'" [smLabelClass]="'col-md-3'"
                    [smMaxlength]="50" [smName]="'vat'" [smType]="'number'" [(smModel)]="item.vatAmount" />
            </div>

            <div class="row">
                <lib-text-input
                    [smLabel]="(isLedgerAccountVatable != null ? isLedgerAccountVatable :params.showVat) ? Inclusive : Amount"
                    [smLabelClass]="'col-md-3'" [smName]="'inclusiveAmmount'" [smType]="'decimal'" [smMaxlength]="50" [smInputClass]="'form-control text-start'"
                    [(smModel)]="item.inclusiveAmmount" (smModelChange)="calculateInclusiveAmount()"
                    [smRequired]="false" />
            </div>
            <div class="button-bar">
                <lib-button [smLabel]="'resources.modal-buttons-ok'" [smType]="'submit'" (smClick)="passBack(frm)"
                    [smIcon]="'fa fa-check'" [smClass]="'btn btn-secondary'" />
                <lib-button [smLabel]="'resources.modal-buttons-cancel'" [smType]="'button'" (smClick)="closeModal()"
                    [smIcon]="'fa fa-minus'" [smClass]="'btn btn-quaternary'" />
            </div>
        </lib-form-panel>
    </form>
</div>
<div class="modal-footer">
</div>