 <lib-table 
[tableColumns]="ledgerColumns" 
[api]="api" 
[title]="title" 
[exportButton]="exportButton"
[addNewButton]="addNewButton"
[pdfButton]="pdfButton"
[searchUIOptions]="searchUIOptions"
[tabData]="tabData" 
(smHandleAddButtonClick)="handleAddButtonClick($event)"
[helpLinkURL]="supportVideoUrl"
[resourceMessages]="resourceMessages"
>
</lib-table>
