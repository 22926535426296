<div class="container">
    <div *ngIf="isLoading" class="d-flex flex-column align-items-center justify-content-center">
        <div style="height: 400px;" class="d-flex justify-content-center"  >
            <div class="sm-spinner text-center">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <iframe #iframe id="reportIframe" [hidden]="isLoading"  [src]="getPdfUrl()" style="z-index:q;position:relative; width: 100%;border: solid 1px white;z-index: 2;">
    </iframe>
</div>