<section id="dashboard-view">
    <div class="bs-callout bs-callout-warning mt-4">
        <div class="alert alert-warning">
            
                <h4><i class="fa fa-exclamation-triangle"></i> <span >{{pageNotFoundText}}</span></h4>
                <span>{{pageDoesNotExistText}}</span> <b>'{{currentLocation}}'</b> <span>{{doesNotExistText}}</span>  

            
        </div>
    </div>
</section>
