<div class="modal-header">
    <h3 class="modal-title pull-left">
    <i class="fa fa-question-circle"></i> 
    <span class="nameSpace">{{modalInstance.title}}</span>
    </h3>
    <lib-button
    [smTitle]="'button'"
    [smClass]="'btn btn-close pull-right'"
    (smClick)="activeModal.dismiss()"
  />
</div>
<div class="modal-body">
    <p>{{modalInstance.message}}</p>
</div>
<div class="modal-footer">
    <div class="button-bar ">
      <lib-button
      [smLabel]="'resources.common-button-saveandcontinue'"
      [smType]="'button'"
      [smClass]="'btn btnSecondaryAction btn-secondary'"
      (smClick)="passBack()"
      [smIcon]="'fa fa-plus'"
    />

     <lib-button
      [smLabel]="'resources.common-button-no'"
      [smType]="'button'"
      [smClass]="'btn btnSecondaryAction btn-primary'"
      (smClick)="activeModal.dismiss()"
      [smIcon]="'fa fa-minus'"
    />
    </div>
</div>
