
<h1>
    <span translate="resources.companyprofilewizard-pageheading"></span>
</h1>

<div>
<form class="form-horizontal"  #frmcompanyprofilewizard="ngForm" (submit)="finishedWizard()"   novalidate>

    <mat-stepper headerPosition="bottom" #stepper [linear]="true" (selectionChange)="onStep1Click()">
  <mat-step [completed]="isCompleted">
          <ng-template matStepperIcon="edit">
            <mat-icon>done</mat-icon>
          </ng-template>
          <ng-template matStepLabel ></ng-template>
            <lib-form-panel>
                <div class="form-group">
                    <h4 class="col-md-12">{{step}} 1</h4>
                </div>
                <div class="form-group" [hidden]="!isProvisioned">
                    <div class="col-md-12">
                        <strong>
                            <span translate="resources.companyprofilewizard-label-companyloginname-info"></span>
                        </strong>
                    </div>
                </div>
                <lib-text-input [hidden]="!isProvisioned" [smLabel]="'resources.companyprofilewizard-label-companyloginname'" [smLabelClass]="'col-sm-3 control-label'" [smName]="'companyLoginName'" [smType]="'text'" [(smModel)]="companySettings.companyLoginName" [smMaxlength]="50" [smRequired]="true" />
                <div class="form-group" [hidden]="!isProvisioned">
                    <br />
                </div>
                <div class="form-group">
                    <div class="col-md-11">
                        <strong>
                            <span translate="resources.companyprofilewizard-label-information"></span>
                        </strong>
                    </div>
                    <div class="col-md-12">
                        <i class="fa fa-question-circle fa-lg pull-right" (click)="showInfo('companyprofile')" title="{{'resources.common-buttons-info-title' | translate }}"></i>
                    </div>
                </div>
                <div class="col-md-6">
                <label class="control-label" class="col-md-5 control-label" style="padding-right: 30px;transform: translateY(-25%);">
                    <div [translate]="'resources.companyprofilewizard-label-country'"></div>
                </label>
                  <label class="form-control-static" class="col-md-6 control-label">
                    <span class="pull-left"><img class="img-responsive center-block flag" title="{{companySettings.countryName}}" src="{{companySettings.countryImageUrl}}" /></span>
                    <span class="pull-left">&nbsp;&nbsp;{{companySettings.countryName}}</span>
                </label>
                </div>
                <div class="col-md-7">
                  <lib-select-input [smLabel]="'resources.companyprofilewizard-label-companytype'" [smLabelClass]="'col-md-4 control-label'" [smName]="'organisationType'" [(smModel)]="companySettings.organisationTypeId" [smOptionDisplayField]="'value'" [smOptions]="businessTypes" [smOptionValueField]="'key'" />
                </div>
                <div class="col-md-7">
                <lib-text-input [smLabel]="'resources.companyprofilewizard-label-registeredname'" [smLabelClass]="'col-md-4 control-label'" [smName]="'registeredName'" [smType]="'text'" [(smModel)]="companySettings.registeredName" [smMaxlength]="200" [smRequired]="true" />
                </div>
                <div class="col-md-7">
                <lib-text-input [smLabel]="'resources.companyprofilewizard-label-tradingname'" [smLabelClass]="'col-md-4 control-label'" [smName]="'tradingName'" [smType]="'text'" [(smModel)]="companySettings.tradingName" [smMaxlength]="200" [smRequired]="true" />
              </div>
              <div class="col-md-7">
                <lib-date-picker [smLabel]="'resources.companyprofilewizard-label-registereddate'" [smLabelClass]="'col-md-4 control-label'" [smName]="'registeredDate'" [(smModel)]="companySettings.registeredDate"/>
              </div>
              <div class="col-md-7">
                <lib-text-input *ngIf="companyTypeDisplayModel.isVisable" [smLabel]="companyTypeDisplayModel.ownerTextLabel" [smLabelClass]="'col-md-4 control-label'" [smName]="'membersNames'" [smType]="'text'" [(smModel)]="companySettings.membersNames" [smMaxlength]="300" />
              </div>
              <div class="col-md-7">
                <lib-text-input *ngIf="companyTypeDisplayModel.isVisable" [smLabel]="companyTypeDisplayModel.ckTextLabel" [smLabelClass]="'col-md-4 control-label'" [smName]="'ckNumber'" [smType]="'text'" [(smModel)]="companySettings.ckNumber" [smMaxlength]="50" />
              </div>
                <div class="form-group">
                    <div class="col-md-12">
                        <i class="fa fa-question-circle fa-lg pull-right" (click)="showInfo('vatInfo')" title="{{'resources.common-buttons-info-title' | translate }}"></i>
                    </div>
                </div>
                <div class="form-group">
                  <div style="padding-left: 20%; display: flex" class="col-md-12">
                    <div class="d-flex align-items-center">
                      <label class="d-flex align-items-center">
                        <input class="radioButton" type="radio" name="inlineRadioOptions1" [value]="true" [(ngModel)]="companySettings.isVatRegistered" (change)="isVatableChange(companySettings.isVatRegistered)" >
                          <span class="radioText" translate="resources.companyprofilewizard-step3-label-vatregistered"></span>
                      </label>
                      <label class="d-flex align-items-center">
                        <input class="radioButton" type="radio" name="inlineRadioOptions2" [value]="false" [(ngModel)]="companySettings.isVatRegistered" (change)="isVatableChange(companySettings.isVatRegistered)">
                          <span class="radioText" translate="resources.companyprofilewizard-step3-label-notvatregistered"></span>
                      </label>
                    </div>
                  </div>
              
                </div>
                <div class="col-md-7" [hidden]="!companySettings.isVatRegistered" >
                <lib-text-input [smRequired]="true" [smLabel]="'resources.companyprofilewizard-step3-label-vatnumber'" [smLabelClass]="'col-md-4 control-label'" [smName]="'vatNumber'" [smType]="'text'" [(smModel)]="companySettings.vatNumber" [smMaxlength]="50" />
              </div>
              </lib-form-panel>
            <lib-form-panel>
                <div class="button-bar">
                   <lib-button [smLabel]="'resources.common-buttons-next'" [smType]="'button'" (smClick)="checkVatSelectionModal(stepper)" [smIcon]="'fa fa-chevron-circle-right'" [smClass]="'btn btn-primary btnPrimaryAction'" />
                </div>
            </lib-form-panel>
        </mat-step>
        <mat-step [completed]="false">
          <ng-template matStepLabel></ng-template>
            <lib-form-panel>
              <div class="col-md-7">
                <lib-select-input [smLabel]="'I currently do my accounting using'" [smLabelClass]="'col-md-4 control-label'" [smName]="'howDoYouDoYourAccounting'" [(smModel)]="companySettings.setupWizardQuestions.howDoYouDoYourAccounting" [smOptionDisplayField]="'value'" [smOptions]="setupWizardQuestions.howDoYouDoYourAccounting" [smOptionValueField]="'key'" />
              </div>
              <div class="col-md-7">
                <lib-select-input [smLabel]="'I have been up and running for'" [smLabelClass]="'col-md-4 control-label'" [smName]="'organisationType'" [(smModel)]="companySettings.setupWizardQuestions.howLongHaveYouBeenRunning" [smOptionDisplayField]="'value'" [smOptions]="setupWizardQuestions.howLongHaveYouBeenRunning" [smOptionValueField]="'key'" />
              </div>
              <div class="col-md-7">
                <lib-select-input [smLabel]="'My industry is'" [smLabelClass]="'col-md-4 control-label'" [smName]="'organisationType'" [(smModel)]="companySettings.setupWizardQuestions.yourIndustryIs" (smModelChange)="companyTypeChange()" [smOptionDisplayField]="'value'" [smOptions]="setupWizardQuestions.yourIndustryIs" [smOptionValueField]="'key'" />
              </div>
              <div class="col-md-7">
                <lib-select-input [smLabel]="'I heard about you from'" [smLabelClass]="'col-md-4 control-label'" [smName]="'organisationType'" [(smModel)]="companySettings.setupWizardQuestions.youHeardAboutUsFrom" (smModelChange)="companyTypeChange()" [smOptionDisplayField]="'value'" [smOptions]="setupWizardQuestions.youHeardAboutUsFrom" [smOptionValueField]="'key'" />
              </div>
                <br />
                <br />
                <div class="form-group">
                    <div class="col-sm-12 pull-left">
                        <strong>
                            <span style="margin-right:5px;" translate="resources.companyprofilewizard-step5-label-firstmessage"></span>
                            <lib-link [url]="supportVideoBaseUrl" [linkName]="'Help Videos'" [translate]="'resources.companyprofilewizard-step5-label-helpvideos'" [target]="'blank'" ></lib-link>
                        </strong>
                    </div>
                    <br />
                    <br />
                </div>
            </lib-form-panel>
            <lib-form-panel>
                <div class="button-bar">
                    <lib-button mat-button matStepperPrevious [smLabel]="'resources.common-buttons-previous'" (smClick)="goBack(stepper)"  [smType]="'button'" [smIcon]="'fa fa-chevron-circle-left'" [smClass]="'btn btn-quaternary btnSecondaryAction'" />
                    <lib-button mat-button matStepperNext [smLabel]="'resources.common-buttons-setupcomplete'" [smType]="'submit'" [smIcon]="'fa fa-check-circle'" [smClass]="'btn btn-primary btnPrimaryAction'" />
                </div>
            </lib-form-panel>
        </mat-step>
    </mat-stepper>
</form>
</div>
