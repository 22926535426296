<form novalidate name="frm">

    <div class="row">
        <div class="col-md-12">
            <web-dashboard-details-report></web-dashboard-details-report>
        </div>
    </div>
    <!--<div class="row">
        <div class="col-md-12">
            <a class="btn btn-secondary" href="contacts/organisations/0"><i class="fa fa-plus"></i> Add New Organisation</a>
            <a class="btn btn-secondary" href="staff/payslips/0"><i class="fa fa-plus"></i> Add New Payslip</a>
            <a class="btn btn-secondary" href="finance/quotes/quotes/0"><i class="fa fa-plus"></i> Add New Quote</a>
            <a class="btn btn-secondary" href="finance/invoicing/customerinvoices/0"><i class="fa fa-plus"></i> Add Customer Invoice</a>
            <a class="btn btn-secondary" href="finance/invoicing/supplierinvoices/0"><i class="fa fa-plus"></i> Add Supplier Invoice</a>
            <a class="btn btn-secondary" href="finance/bankaccounts/import"><i class="fa fa-plus"></i> Upload Bank Statement</a>
        </div>
    </div>-->
    <div class="row">
        <div class="col-md-6">
            <div>
                <web-cash-flow-dashboard-report></web-cash-flow-dashboard-report>
            </div>
        </div>
        <div class="col-md-6">
            <web-invoice-owed-dashboard-report></web-invoice-owed-dashboard-report>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <web-invoice-owing-dashboard-report></web-invoice-owing-dashboard-report>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <web-cash-flow-expense-dashboard-report></web-cash-flow-expense-dashboard-report>
        </div>
    </div>
</form>
