<div class="body-login">
    <section id="login-view" class="center-block login-view">
        <div class="center-block container login-container" [style]="{width:'600px'}">
            <!-- <div class="login-logo-container d-flex align-items-center justify-content-center">
                <img [src]="loginScreenMainLogoUrl" class="img-responsive loginLogo center-block" />
            </div> -->
            <div class="login-logo-container logo">

            </div>
            <div class="d-flex justify-content-center"  >
                <div class="text-center">
                    <h4 class="login-header" >Forgot Password</h4>
                </div>
            </div>
            <!-- <div class="row">
                <div class="form-group row">
                    <div class="control-label col-md-4"></div>
                    <div class="col-md-8">
                        <h4 class="login-header">Forgot Password</h4>
                    </div>
                </div>
            </div> -->
            <div [hidden]="isPasswordReset===true" class="alert alert-dismissible alert-forgotpassword">
                <p>Please enter your <span style="font-weight: bold;">Company Login Name</span> and <span style="font-weight: bold;">Username</span> below. Or return to the <a href="login" class="forgotpasswordlink" translate="resources.login-button-login"></a> screen.
            </div>
            <form class="form-signin" #sendOtpForm="ngForm" novalidate>
                <div [hidden]="isPasswordReset===true">
                    <div class="col-md-10"> 
                        <lib-text-input [smLabel]="'resources.login-label-companyloginname'" [smLabelClass]="'col-md-5'" [smName]="'companyloginname'" [smType]="'text'" [(smModel)]="credentials.companyloginname" [smRequired]="true" />
                        <lib-text-input [smLabel]="'resources.login-label-username'"  [smLabelClass]="'col-md-5'" [smName]="'username'" [smType]="'text'" [(smModel)]="credentials.username" [smRequired]="true" />
                    </div>
                    <div ng-hide="isPasswordReset===true" class="row">
                        <div class="alert alert-dismissible alert-forgotpassword">
                            <p>Then enter your new password. Remember passwords must be at least <span style="font-weight: bold;">10 characters long</span> and contain at least <span style="font-weight: bold;">1 special character</span>. When done request a One Time PIN (OTP) for verification.
                        </div>
                    </div>
                    <div class="col-md-10"> 
                        <lib-text-input [smLabel]="'Password'" [smLabelClass]="'col-md-5'" [smName]="'password'" [smType]="'password'" [(smModel)]="credentials.password" [smRequired]="true" />
                        <lib-text-input [smLabel]="'Confirm Password'" [smLabelClass]="'col-md-5'" [smName]="'confirmPassword'" [smType]="'password'" [(smModel)]="credentials.confirmPassword" [smRequired]="true" />
                        <div class="form-group row">
                            <div class="control-label col-md-5">

                            </div>
                            <div class="control-label col-md-7">
                                <lib-button [smLabel]="'Send OTP Email'" [smType]="'button'" (smClick)="sendOtpEmail(credentials, sendOtpForm)" [smClass]="'btn btn-sm btn-quaternary btnSecondaryAction pull-left'" />
                                <lib-button [smLabel]="'Send OTP SMS'" [smType]="'button'" (smClick)="sendOtpSms(credentials, sendOtpForm)" [smClass]="'btn btn-sm btn-quaternary btnSecondaryAction pull-left'" />
                            </div>
                        </div>
                    </div>

                </div>
            </form>
            <div [hidden]="!isOtpSent===true || isPasswordReset===true" class="alert alert-dismissible  alert-forgotpassword">
                <p>Your OTP has been sent to you via the relevant channel. If you do not receive it please double check the <span style="font-weight: bold;">Company Login Name</span> and <span style="font-weight: bold;">Username</span> that you have entered and verify that they are correct. </p>
                <p>Once you receive your OTP, please enter it below.</p>
            </div>
            <form (submit)="resetPassword(credentials, forgotpasswordForm, sendOtpForm)" class="form-signin"  #forgotpasswordForm="ngForm"  novalidate>
                <div [hidden]="!isOtpSent===true || isPasswordReset===true">
                    <div class="col-md-10"> 
                        <lib-text-input [smLabel]="'OTP'" [smLabelClass]="'col-md-5'" [smName]="'otp'" [smType]="'text'" [(smModel)]="credentials.otp" [smRequired]="true" />
                        <div class="form-group row">
                            <div class="control-label col-md-5">

                            </div>
                            <div class="control-label col-md-7">
                                <lib-button [smLabel]="'resources.forgottenpwd-button-resetpwd'" [smType]="'submit'" [smClass]="'btn btn-sm btn-primary btnPrimaryAction pull-left'" />
                                <a href="login"class="footersupportlink" translate="resources.login-button-login"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="alert alert-dismissible alert-forgotpassword" [hidden]="!isPasswordReset===true">
                <p>Your password has been reset. You should now be able to login with your new password.</p>
                <p><a href="login" class="glyphicon-color forgotpasswordlink" style="font-weight: bold;" translate="resources.forgottenpwd-clickheretologin-msg"></a></p>
            </div>
        </div>
    </section>
</div>
