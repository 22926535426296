import { Component } from '@angular/core';
import { AuthService } from 'packages/shared-lib/src/lib/services/auth.service'
import { NavigationService } from 'packages/shared-lib/src/lib/services/navigation.service';
import { LocalStorageService } from 'packages/shared-lib/src/lib/services/local-storage.service';
import { MessagingService } from 'packages/shared-lib/src/lib/services/messaging.service';
import { webApi } from 'packages/shared-lib/src/lib/services/api/webclient.api';
import { webConstants } from 'packages/shared-lib/src/lib/constants/web.constants';
import { LookupDataService } from 'packages/shared-lib/src/lib/services/lookup-data.service';
import { NotificationBarService } from 'packages/shared-lib/src/lib/services/notification-bar.service';
import { EnumsService } from 'packages/shared-lib/src/lib/services/enums.services';
@Component({
  selector: 'web-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(
              private authService: AuthService,
              private navigationService: NavigationService,
              private messagingService: MessagingService,
              private localStorageService: LocalStorageService,
              private lookupDataService: LookupDataService,
              private notificationService:NotificationBarService,
              private enumsService: EnumsService
             ) {}

  loginScreenMainLogoUrl = webConstants.currentEnv.loginScreenMainLogoUrl;
  supportVideoBaseUrl = webConstants.currentEnv.supportLinkUrl;
  disclaimerText = webConstants.currentEnv.loginDisclaimerText;
  forgotpassword = this.navigationService.goToForgotPassword;
  
  credentials = {
      companyname: '',
      username: '',
      password: ''
  };
  loginForm={

  }

  //TODO - commented portion
  login(credentials: any, form: any) {
      this.messagingService.broadcastCheckFormValidatity();
      if (!form.invalid) {
        const payload = 'grant_type=password&username=' + credentials.companyname + '|' +
                      credentials.username + '&password=' + credentials.password;
          this.authService.login(payload, webApi.token).subscribe((res: any) => {
              this.rememberLoginCredentials(credentials.companyname, credentials.username);
              this.lookupDataService.getCompanyProfile(true, false).subscribe((response:any)=>{
                if(response.isWizardVisible){
                  this.navigationService.goToCompanyProfileWizard();
                }
                else{
                  if (this.authService.hasRequiredPermission(this.enumsService.permissions.CanAccessDashboard)){
                    this.navigationService.goToDashboard();
                  }
                  else{
                    this.navigationService.goToContactsDashboard();
                  }
                }
              })
          this.messagingService.broadcastLoginSuccess();
          });
      }
    }
  


  ngOnInit() {
      this.getLoginCredentials();
      //force logout when loading the login page
      this.authService.logOutWithoutRedirect();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getLoginCredentials() {
    if (this.localStorageService.get('login.companyLoginName') !== undefined ) {
      const companyLoginName:any = this.localStorageService.get('login.companyLoginName');
      this.credentials.companyname = companyLoginName;
    }

    if (this.localStorageService.get('login.username') !== undefined ) {
      const username:any = this.localStorageService.get('login.username');
      this.credentials.username = username;
    }
  }

  rememberLoginCredentials(companyLoginName: string, username: string) {
      this.localStorageService.set('login.companyLoginName', companyLoginName);
      this.localStorageService.set('login.username', username);
  }
}
