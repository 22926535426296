<div class="modal-header delete-modal">
  <h3 class="modal-title pull-left">
    <i class="fa fa-exclamation-triangle"></i>
    <span class="nameSpace" translate="resources.common-modaltitle-deleteconfimation"></span>
  </h3>
  <lib-button
    [smTitle]="'button'"
    [smClass]="'btn pull-right thin-close-button'"
    [smIcon]="'fa fa-close-thin'"
    (smClick)="closeModal()"
  />
</div>
<div class="modal-body">
  <p [innerHTML]="confirmationMessage"></p>
</div>
<div class="modal-footer">
  <div class="button-bar">
    <lib-button
      [smLabel]="'resources.common-button-no'"
      [smType]="'button'"
      [smClass]="'btn btn-quaternary'"
      (smClick)="closeModal()"
    />

    <lib-button
      [smLabel]="'resources.common-button-yes'"
      [smType]="'button'"
      [smClass]="'btn btn-danger btnPrimaryAction delete-yes-button'"
      (smClick)="passBack()"
    />
  </div>
</div>
