<div class="mt-4">
    <div class="bs-callout bs-callout-warning">
      <div class="alert alert-warning">
        <p>
          <span><i class="fa fa-exclamation-triangle"></i> Insufficient
            Permissions</span>
        </p>
        <p>
          Sorry, you have not been given access to <b>{{ screenName }}</b>
        </p>
        <p>Please contact your company's administrator.</p>
      </div>
    </div>
</div>
