import { Component } from "@angular/core";
import { webConstants } from "packages/shared-lib/src/lib/constants/web.constants";
import { MessagingService } from "packages/shared-lib/src/lib/services/messaging.service";
import { ActivatedRoute } from "@angular/router";
import { CompleteRegistrationService } from "packages/shared-lib/src/lib/services/complete-registration.service"

@Component({
  selector: "web-login",
  templateUrl: "./complete-registration.component.html",
  styleUrls: ['../login.component.scss'],
})
export class CompleteRegistrationComponent {
  constructor(
    private messagingService: MessagingService,
    private activatedRoute: ActivatedRoute,
    private completeRegistrationService: CompleteRegistrationService
) {
  this.activatedRoute.params.subscribe((params) => {
    this.completeRegistrationToken = params["id"];
  });
}
  
  loginScreenMainLogoUrl = webConstants.currentEnv.loginScreenMainLogoUrl; 
  companyDetails = {
      companyloginname: '',
      username: '',
      password: '',
      confirmPassword: '',
      otp: '',
      otpChannel: '',
      otpType: 'Registration'
  };
  isRegistrationComplete: any
  isOtpSent: any
  completeRegistrationToken: any
  
  ngOnInit(){
    this.isRegistrationComplete = false;
    this.isOtpSent = false;
    this.getCompleteRegistrationDetail();
  }
  
  termsAndConditionsUrl = webConstants.currentEnv.termsAndConditionsUrl;
  termsAndConditionsChecked : any= false;

  completeRegistration(payload:any, form1:any, form2:any) {
      this.messagingService.broadcastCheckFormValidatity();
      if (form1.valid && form2.valid) {
          this.completeRegistrationService.completeRegistration(payload)
              .subscribe(() => {
                  this.isRegistrationComplete = true;
              });
      }
  };

  getCompleteRegistrationDetail() {
      if (this.completeRegistrationToken) {
          this.completeRegistrationService.getCompanyDetailsForOtp(this.completeRegistrationToken)
              .subscribe((results:any) => {
                  this.companyDetails.companyloginname = results.companyLoginName;
                  this.companyDetails.username = results.username;
                  this.isRegistrationComplete = results.isRegistrationComplete;
              });
      }
  };

  sendOtpSms(payload:any, form:any) {
      this.messagingService.broadcastCheckFormValidatity();
      if (form.valid) {
        payload.otpChannel = 'Sms';
        this.completeRegistrationService.requestOtp(payload)
          .subscribe(() => {
              this.isOtpSent = true;
          });
      }
  }

  sendOtpEmail(payload:any, form:any) {
      this.messagingService.broadcastCheckFormValidatity();
      if (form.valid) {
        payload.otpChannel = 'Email';
        this.completeRegistrationService.requestOtp(payload)
          .subscribe(() => {
              this.isOtpSent = true;
          });
      }
  }

  checkEvent() {
    if(this.termsAndConditionsChecked == false){
      this.termsAndConditionsChecked = true
    }
    else{
      this.termsAndConditionsChecked = false
    }
  }
}
