import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { portalConstants } from "../../../constants/portal.constants";

@Component({
  selector: "lib-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent {


  constructor(private router: Router,
    private translateService : TranslateService){}

  currentLocation:any ="";
  pageNotFoundText:any ="";
  pageDoesNotExistText :any ="";
  doesNotExistText :any ="";
  
  ngOnInit(){
      this.currentLocation = this.router.url;
      this.initTranslation();
  }
   
  initTranslation() {
    this.translateService.get('resources.common-pagenotfound-label').subscribe((res: any) => {
      this.pageNotFoundText =  res !== undefined && res !== '' && res !== 'resources.common-pagenotfound-label' ? res : portalConstants.pageNotFoundText;
    });

    this.translateService.get('resources.common-sorrypagedoesnotexist-label').subscribe((res: string) => {
      this.pageDoesNotExistText = res !== undefined && res !== '' && res !== 'resources.common-sorrypagedoesnotexist-label' ? res : portalConstants.pageDoesNotExistText;
    });

    this.translateService.get('resources.common-doesnotexist-label').subscribe((res: string) => {
      this.doesNotExistText = res !== undefined && res !== '' && res !== 'resources.common-doesnotexist-label' ? res : portalConstants.doesNotExistText;
    });
  
  
  }


}
