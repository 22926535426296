<lib-table [tableColumns]="customerColumns"
[title] ="title"
[exportButton]="exportButton"
[pdfButton]="pdfButton"
[refreshButton]="refreshButton"
[addNewButton]="addNewButton"
[tabData]="tabData"
[api]="api"
[resourceMessages]="resourceMessages"
[blockquote]="blockquote"
[totalColumn]="totalColumn"
[additionalGetPageResponse]="additionalGetPageResponse"
[searchUIOptions]="searchUIOptions"
[dateFilter]="dateFilter"
[helpLinkURL]="helpLinkURL"
[resourceMessages]="resourceMessages"
[singleDateFilterDivClass]="'col-md-6'"
>
</lib-table>