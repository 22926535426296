import { Component } from '@angular/core';
import { navButtonGrid } from 'packages/shared-lib/src/lib/interfaces/webclient.interface';
import { EnumsService } from 'packages/shared-lib/src/lib/services/enums.services';

@Component({
  selector: 'web-contacts',
  templateUrl: './contacts.component.html',
})
export class ContactsComponent{
  constructor(
    private enumService: EnumsService
){}

  navButtonList: navButtonGrid[] = [
    {
      text:'resources.dashboard-contacts-customersuppliers',
      subText:'resources.dashboard-contacts-customersuppliers-organisations',
      sref: '/contacts/organisations',
      buttonSupportName: this.enumService.dashboardButtons.ContactsOrganisations
    },
    {
      text:'resources.dashboard-contacts-salesleads',
      sref: '/contacts/salesleads',
      buttonSupportName: this.enumService.dashboardButtons.ContactsSalesleads
    }
  ];
}
