<lib-table 
[tableColumns]="userColumns" 
[api]="api" 
[title]="title" 
[searchUIOptions]="searchUIOptions"
[resourceMessages]="resourceMessages"
(smHandleAddButtonClick)="handleAddButtonClick($event)"
(smHandleUpdateButtonClick)="handleUpdateButtonClick($event)"
[additionalGetPageResponse]="additionalGetPageResponse"
>
</lib-table>