<lib-table
  [api]="api"
  [title]="title"
  *ngIf="dataLoaded"
  [helpLinkURL]="helpLinkURL"
  [searchUIOptions]="searchUIOptions"
  [resourceMessages]="resourceMessages"
  [tableColumns]="customerInvoiceColumns" 
  (smHandleAddButtonClick)="handleAddButtonClick($event)"
  (smHandleCheckboxUpdate)="handlePaidStatus($event)"
  [dateRangeDivClass]="'col-md-4'"
  [searchInputDivClass]="'col-md-4'"
  [isCustomerInvoice]="true"
  [datepickerShowWeeks]="false"
  [smUrl]="updateURL"
></lib-table>