<div class="row">
  <div class="mt-4 col-md-6 mb-2">
    <h3>
      <span
        translate="resources.settings-users-pageheading-userpermissions"
      ></span>
    </h3>
  </div>
  <div class="col-md-6 button-bar marginTop2">
    <a class="help-link" href="{{ helpLinkURL }}" target="_blank">
      <span translate="resources.common-buttons-helpvideo"></span>
    </a>
  </div>
</div>

<lib-page-tab-bar [tabData]="tabData"> </lib-page-tab-bar>

<div class="row mt-3">
  <div class="form-group col-md-12"></div>
</div>

<form
  class="form-horizontal"
  (submit)="save()"
  #frmPermissoin="ngForm"
  novalidate
>
  <table class="table table-hover table-bordered">
    <thead>
      <tr>
        <th scope="col">
          <span
            translate="resources.settings-users-tablecolumnheading-allow"
          ></span>
        </th>
        <th scope="col">
          <span
            translate="resources.settings-users-tablecolumnheading-description"
          ></span>
        </th>
      </tr>
    </thead>
    <tbody *ngFor="let item of userPermissionSet; let i = index">
      <tr *ngIf="i === 0">
        <td style="text-align: center">
          <span>
            <lib-checkbox-input
              [(smModel)]="isSelectAllSelected"
              (smModelChange)="selectAllChange()"
            />
            <span class="box">
              <span class="tick"></span>
            </span>
          </span>
        </td>

        <td style="padding-left: 15px">
          <b
            ><span translate="resources.settings-users-label-selectall"></span>
          </b>
        </td>
      </tr>
      <tr>
        <td></td>
        <td style="padding-left: 15px">
          <h5 translate="resources.{{ item.key }}"></h5>
        </td>
      </tr>
      <tr *ngFor="let permission of item.value">
        <td style="text-align: center">
          <span *ngIf="permission.id != userPermissionEnum.None">
            <lib-checkbox-input
            *ngIf="permission.id != userPermissionEnum.None"
              [(smModel)]="permission.isAllowed"
              (smModelChange)= "isSystemLockoutSelected()"
              [smDisabled] = "userPermissions"
            ></lib-checkbox-input>

            <span class="box">
              <span class="tick"></span>
            </span>
          </span>
          <span *ngIf="permission.id == userPermissionEnum.None">
            <lib-checkbox-input
              *ngIf="permission.id == userPermissionEnum.None"
              [(smModel)]="permission.isAllowed"
              (smModelChange)="systemLockoutPermissionChange()"
            />
            <span class="box">
              <span class="tick"></span>
            </span>
          </span>
        </td>
        <td
          style="padding-left: 50px"
          translate="resources.{{ permission.permissionTextTranslationKey }}"
        ></td>
      </tr>
    </tbody>
  </table>
  <div class="button-bar mb-5">
    <lib-button
      [smLabel]="'resources.common-buttons-save'"
      [smType]="'submit'"
      [smIcon]="'fa fa-save'"
      [smClass]="'btn btn-primary pull-right btnPrimaryAction'"
    />
  </div>
</form>
