<lib-table [tableColumns]="customerInvoiceColumns"
[title] ="title"
[exportButton]="true"
[pdfButton]="true"
[refreshButton]="true"
[addNewButton]="false"
[tabData]="tabData"
[helpLinkURL]="helpLinkUrl"
[api]="api"
[resourceMessages]="resourceMessages"
[blockquote]="blockquote"
[totalColumn]="totalColumn"
[additionalGetPageResponse]="additionalGetPageResponse"
[searchUIOptions]="searchUIOptions"
[emptyDefaultDates]="true"
[dateRangeDivClass]="'col-md-4'"
[searchInputDivClass]="'col-md-4'"
>
</lib-table>