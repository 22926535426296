import { Component } from "@angular/core";
import { AuthService } from "packages/shared-lib/src/lib/services/auth.service";
import { DashboardButtonService } from "packages/shared-lib/src/lib/services/dashboard-button.service";
import { LocalStorageService } from "packages/shared-lib/src/lib/services/local-storage.service";
import { LookupDataService } from "packages/shared-lib/src/lib/services/lookup-data.service";
import { MessagingService } from "packages/shared-lib/src/lib/services/messaging.service";
import { NavigationService } from "packages/shared-lib/src/lib/services/navigation.service";

@Component({
  selector: "web-sso",
  templateUrl: "./sso.component.html",
})
export class SsoComponent{
  constructor(
    private authService: AuthService,
    private messagingService: MessagingService,
    private lookupDataService: LookupDataService,
    private navigationService: NavigationService,
    private dashboardButtonService: DashboardButtonService,
    private localStorageService: LocalStorageService,
    ){

  }
  ngOnInit() {
    this.activate();
  }
  authenticate(jwt:any){
    if (jwt) {
        console.log('ssoauth.controller - authenticating jwt: ', jwt);
        this.authService.mtnSsoLogin(jwt);
        this.messagingService.broadcastLoginSuccess();
        // this.dashboardButtonService.initService(true);
        console.log('ssoauth.controller - calling getCompanyProfile');
        this.lookupDataService.getCompanyProfile(true,false).subscribe((result) =>{
            console.log('ssoauth.controller - call to getCompanyProfile returned with result: ', result);
            const companyProfile:any = result;
            setTimeout(()=>{
              console.log('ssoauth.controller - redirecting depending on company profile');
              this.navigationService.redirectDependingOnCompanyProfile(companyProfile, true);
            }, 250);
        });
    }
  }

  activate() {
      console.log('ssoauth.controller - activate');
      const jwtToken = new URLSearchParams(window.location.search).get('jwt');
      console.log(jwtToken)
      this.authenticate(jwtToken);
  }
}
