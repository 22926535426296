import { Component, OnInit } from "@angular/core";
import { SettingsDataService } from "packages/shared-lib/src/lib/services/settings-data.service";
import { LookupDataService } from "packages/shared-lib/src/lib/services/lookup-data.service";
import { TranslationService } from "packages/shared-lib/src/lib/services/translation.service";
import { EnumsService } from "packages/shared-lib/src/lib/services/enums.services";
import { ModalService } from "packages/shared-lib/src/lib/services/modal.service";
import { NotificationBarService } from "packages/shared-lib/src/lib/services/notification-bar.service";
import { MessagingService } from "packages/shared-lib/src/lib/services/messaging.service";
import { TranslateService } from "@ngx-translate/core";
import { NavigationService } from "packages/shared-lib/src/lib/services/navigation.service";
import { SessionService } from "packages/shared-lib/src/lib/services/session.service";
import { webConstants } from "packages/shared-lib/src/lib/constants/web.constants";
import { UtilityDataService } from "packages/shared-lib/src/lib/services/utility-data.service";
import { MatStepper } from "@angular/material/stepper";
import {
  companySettings,
  setupWizardQuestions,
} from "packages/shared-lib/src/lib/interfaces/webclient.interface";
import { DataService } from "packages/shared-lib/src/lib/services/data.service";
import { webApi } from "packages/shared-lib/src/lib/services/api/webclient.api";
@Component({
  selector: "web-login",
  templateUrl: "./company-profile-wizard.component.html",
  styleUrls: ['./company-profile-wizard.component.scss'],
})
export class CompanyProfileWizardComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private settingsDataService: SettingsDataService,
    private lookupDataService: LookupDataService,
    private translationService: TranslationService,
    private enumsService: EnumsService,
    private modalService: ModalService,
    private notificationBarService: NotificationBarService,
    private messagingService: MessagingService,
    private translateService: TranslateService,
    private navigationService: NavigationService,
    private sessionService: SessionService,
    private utilityDataService: UtilityDataService
  ) {}
  supportVideoBaseUrl = webConstants.companyprofilesetupURL
  saveSuccessMessage = "";
  warningMessageVat = "";
  warningMessageNoVat = "";
  vatHeading = "";
  membersNames = "";
  ckNumber = "";
  directorsNames = "";
  registrationNumber = "";
  directorsTrusteesNames = "";
  companyProfile = "";
  companyLogo = "";
  vatInfo = "";
  bankingDetails = "";
  companyProfileInfoMsg = "";
  companyLogoMsg = "";
  vatInfoMsg = "";
  numberInfoMsg = "";
  bankInfoMsg = "";
  companySettings: companySettings = {
    organisationType: {},
    membersNames: "",
    isWizardVisible: "",
    organisationTypeId: "",
    companyLoginName: "",
    vatRate: "",
    countryName: "",
    registeredName: "",
    tradingName: "",
    registeredDate: "",
    ckNumber: "",
    isVatRegistered: "",
    vatNumber: "",
    countryImageUrl: "",
    setupWizardQuestions: {
      howDoYouDoYourAccounting: { a: "a", b: "b" },
      howLongHaveYouBeenRunning: "",
      yourIndustryIs: "",
      youHeardAboutUsFrom: "",
    },
  };
  isCompleted= false;
  setupWizardQuestions: setupWizardQuestions = {
    howDoYouDoYourAccounting: "",
    howLongHaveYouBeenRunning: "",
    yourIndustryIs: "",
    youHeardAboutUsFrom: "",
  };
  businessTypes: any;
  companyProfileImage: any;
  companycountry: any;
  uploader: any;
  showProgressbar: any;
  step: any;
  defaultVatRate = 0;
  isProvisioned: any = this.sessionService.isProvisioned;
  getData(refresh: any) {
    this.settingsDataService.getCompanySettings().subscribe((response: any) => {
      this.companySettings = response;
      this.companySettings.companyLoginName = this.sessionService?.companyname;
      if (this.isProvisioned === true) {
        this.companySettings.companyLoginName = "";
      }
      this.defaultVatRate = this.companySettings?.vatRate;
      this.companySettings.setupWizardQuestions = {
        howDoYouDoYourAccounting: "",
        howLongHaveYouBeenRunning: "",
        yourIndustryIs: "",
        youHeardAboutUsFrom: "",
      };
    });
    this.lookupDataService
      .getCompanyProfileImage(true, false)
      .subscribe((response: any) => {
        this.companyProfileImage = response;
      });
    this.lookupDataService
      .getBusinessTypesForCompanySettings(true)
      .subscribe((response: any) => {
        this.businessTypes = response;
      });
    this.lookupDataService
      .getSetupWizardQuestions(true)
      .subscribe((response: any) => {
        this.setupWizardQuestions = response;
      });
    setTimeout(() => {
      this.companyTypeChange();
    }, 1000);
  }
  isVatableChange(value: any) {
    value
      ? (this.companySettings.vatRate = this.defaultVatRate)
      : (this.companySettings.vatRate = 0);
  }
  setCompanyTypeDisplay(
    isVisable: any,
    isRequired: any,
    ownerText?: any,
    ckText?: any
  ) {
    this.companyTypeDisplayModel.isVisable = isVisable;
    this.companyTypeDisplayModel.ownerTextLabel = ownerText;
    this.companyTypeDisplayModel.ckTextLabel = ckText;
    this.companyTypeDisplayModel.isRequired = isRequired;
  }
  companyTypeDisplayModel = {
    isVisable: false,
    ownerTextLabel: this.membersNames,
    ckTextLabel: this.registrationNumber,
    isRequired: true,
  };
  wizardSteps = {
    step1: 1,
    step2: 2,
  };
  initTaxInfo() {
    const resourceKey = this.translationService.getTaxInfoResourceKey();
    this.translateService.get(resourceKey).subscribe((msg: any) => {
      this.vatInfoMsg = msg;
    });
  }
  goBack(stepper: MatStepper) {
    stepper.previous();
  }
  checkVatSelectionModal(stepper: MatStepper) {
    this.isCompleted = true;
    const vatText = this.companySettings.isVatRegistered
      ? this.warningMessageVat
      : this.warningMessageNoVat;
    this.modalService
      .questionModal(this.vatHeading, vatText)
      .result.then(() => {
        if(this.companySettings.tradingName) {
          stepper.next();
        } 
      });
  }
  onStep1Click() {
    this.isCompleted = false;
  }
  companyTypeChange() {
    if (this?.companySettings?.organisationTypeId != undefined) {
      switch (this.companySettings.organisationTypeId) {
        case this.enumsService.businessTypeEnum.soleProprietorZa:
          this.setCompanyTypeDisplay(false, false);
          break;
        case this.enumsService.businessTypeEnum.closeCorpZa:
          this.setCompanyTypeDisplay(
            true,
            true,
            this.membersNames,
            this.ckNumber
          );
          break;
        case this.enumsService.businessTypeEnum.companyPtyLtdZa:
          this.setCompanyTypeDisplay(
            true,
            true,
            this.directorsNames,
            this.registrationNumber
          );
          break;
        case this.enumsService.businessTypeEnum.nonProfitZa:
          this.setCompanyTypeDisplay(
            true,
            true,
            this.directorsTrusteesNames,
            this.registrationNumber
          );
          break;
        default:
          this.setCompanyTypeDisplay(
            true,
            true,
            this.directorsNames,
            this.registrationNumber
          );
      }
    }
  }
  save() {
    const companySettingsCopy = { ...this.companySettings };
    companySettingsCopy.organisationType =
      companySettingsCopy.organisationType.value;
    companySettingsCopy.isWizardVisible = false;
    this.dataService
      .post(webApi.saveCompanyWizard, companySettingsCopy)
      .subscribe(() => {
        this.refreshToken();
      });
  }
  refreshToken() {
    this.dataService.post(webApi.refreshtoken).subscribe((res: any) => {
      this.sessionService.create(res);// jshint ignore:line
      this.messagingService.broadcastCompanyProfileSaved();
      this.navigationService.goToDashboard();
      this.notificationBarService.success(this.saveSuccessMessage);
  });
  }
  finishedWizard() {
    this.save();
  }
  exitValidation(frmcompanyprofile: any) {
    this.messagingService.broadcastCheckFormValidatity();
    return !frmcompanyprofile.invalid;
  }
  showInfo(section: any) {
    let title = "";
    let message = "";
    switch (section) {
      case "companycountry":
        title = "Company Country";
        message = this.companycountry;
        break;
      case "companyprofile":
        title = this.companyProfile;
        message = this.companyProfileInfoMsg;
        break;
      case "companyLogo":
        title = this.companyLogo;
        message = this.companyLogoMsg;
        break;
      case "vatInfo":
        title = this.vatInfo;
        message = this.vatInfoMsg;
        break;
      case "bankingdetails":
        title = this.bankingDetails;
        message = this.bankInfoMsg;
        break;
      default:
    }
    this.modalService.messageModal(title, message);
  }
  initTranslation() {
    this.translateService
      .get("resources.common-messages-savesuccessmessage")
      .subscribe((msg: string) => {
        this.saveSuccessMessage = msg;
      });
    this.translateService
      .get("resources.companyprofilewizard-title-step")
      .subscribe((msg: string) => {
        this.step = msg;
      });
    this.translateService
      .get("resources.companyprofilewizard-vat-warningmessagevat")
      .subscribe((msg: string) => {
        this.warningMessageVat = msg;
      });
    this.translateService
      .get("resources.companyprofilewizard-vat-warningmessagenovat")
      .subscribe((msg: string) => {
        this.warningMessageNoVat = msg;
      });
    this.translateService
      .get("resources.companyprofilewizard-vat-heading-vat")
      .subscribe((msg: string) => {
        this.vatHeading = msg;
      });
    this.translateService
      .get(
        "resources.companyprofilewizard-companytypechange-label-membersnames"
      )
      .subscribe((msg: string) => {
        this.membersNames = msg;
      });
    this.translateService
      .get("resources.companyprofilewizard-companytypechange-label-cknumber")
      .subscribe((msg: string) => {
        this.ckNumber = msg;
      });
    this.translateService
      .get(
        "resources.companyprofilewizard-companytypechange-label-directorsnames"
      )
      .subscribe((msg: string) => {
        this.directorsNames = msg;
      });
    this.translateService
      .get(
        "resources.companyprofilewizard-companytypechange-label-registrationnumber"
      )
      .subscribe((msg: string) => {
        this.registrationNumber = msg;
      });
    this.translateService
      .get(
        "resources.companyprofilewizard-companytypechange-label-directorstrusteesnames"
      )
      .subscribe((msg: string) => {
        this.directorsTrusteesNames = msg;
      });
    this.translateService
      .get(
        "resources.companyprofilewizard-companyprofile-heading-companyprofile"
      )
      .subscribe((msg: string) => {
        this.companyProfile = msg;
      });
    this.translateService
      .get("resources.companyprofilewizard-companylogo-heading-companylogo")
      .subscribe((msg: string) => {
        this.companyLogo = msg;
      });
    this.translateService
      .get("resources.companyprofilewizard-vatinfo-heading-vatinfo")
      .subscribe((msg: string) => {
        this.vatInfo = msg;
      });
    this.translateService
      .get(
        "resources.companyprofilewizard-bankingdetails-heading-bankingdetails"
      )
      .subscribe((msg: string) => {
        this.bankingDetails = msg;
      });
    this.translateService
      .get("resources.companyprofilewizard-companyprofileinfo")
      .subscribe((msg: string) => {
        this.companyProfileInfoMsg = msg;
      });
    this.translateService
      .get("resources.companyprofilewizard-companylogo")
      .subscribe((msg: string) => {
        this.companyLogoMsg = msg;
      });
    this.initTaxInfo();
    this.translateService
      .get("resources.companyprofilewizard-numberinfo")
      .subscribe((msg: string) => {
        this.numberInfoMsg = msg;
      });
    this.translateService
      .get("resources.companyprofilewizard-bankinfo")
      .subscribe((msg: string) => {
        this.bankInfoMsg = msg;
      });
  }
  ngOnInit() {
    this.initTranslation();
    this.getData(true);
  }
}
