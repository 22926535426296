<div class="modal-header clearfix d-block">
    <h3 class="modal-title pull-left">
        <i class="fa fa-plus p-1"></i>
        <span translate="resources.finance-creditnoterequiredinfomodal-pageheading-requiredinformation"></span> - {{modalTitle}}
    </h3>
    <lib-button [smType]="'button'" [smIcon]="'fa fa-close'" (smClick)="cancel()" [smClass]="'btn btn-danger btn-xs pull-right'" />
</div>
<form (submit)="ok(requiredInfoForm)" class="form-horizontal" #requiredInfoForm="ngForm" novalidate>
    <div class="modal-body">
        <div class="row">
            <lib-select-input [smLabel]="'resources.finance-creditnoterequiredinfomodal-label-selectcustomer'" [smLabelClass]="'col-md-4'" [smName]="'customer'" [(smModel)]="contact" (smModelChange)="customerChange(contact.key)" [smRequired]="true" [smPlaceholder]="''" [smOptionDisplayField]="'value'" [smOptions]="customers"  />
        </div>
        <div class="row">
            <lib-select-input [smLabel]="'resources.finance-creditnoterequiredinfomodal-label-selectcreditnote'" [smLabelClass]="'col-md-4'" [smName]="'customerCreditNote'" [(smModel)]="customerCreditNote" [smRequired]="true" [smOptionDisplayField]="'creditNoteNumber'" [smPlaceholder]="''" [smOptions]="customerCreditNotes"  />
        </div>
        <hr style="size: 1px;" />
        <div class="row">
            <div class="form-group col-md-12">
                <label class="col-md-4 text-right">
                    <strong>
                        <span translate="resources.finance-creditnoterequiredinfomodal-label-details"></span>
                    </strong>
                </label>
                <label class="col-md-4 text-left">
                    <span translate="resources.finance-creditnoterequiredinfomodal-label-creditnotestotal"></span>
                </label>
                <label class="control-label col-md-4">{{customerCreditNote?.creditNoteTotal|number:'1.2-2'}}</label>
            </div>
        </div>
        <br />
    </div>
    <div class="modal-footer">
        <div class="button-bar button-bar-modal">
            <lib-button [smLabel]="'resources.modal-buttons-cancel'" [smType]="'button'" (smClick)="cancel()" [smIcon]="'fa fa-minus'" [smClass]="'btn btn-quaternary'" />
            <!-- <lib-button [smLabel]="'resources.common-buttons-save'" [smType]="'submit'"  [smDisabled]="requiredInfoForm.invalid" [smIcon]="'fa fa-plus'" [smClass]="'btn btn-secondary'" /> -->
            <lib-button [smLabel]="'resources.common-buttons-save'" [smType]="'submit'"  [smDisabled]="false" [smIcon]="'fa fa-plus'" [smClass]="'btn btn-secondary'" />
        </div>
    </div>
</form>
