<lib-table *ngIf="isLoaded"
  [api]="api"
  [title]="title"
  [refreshButton]="refreshButton"
  [tabData]="tabData"
  [helpLinkURL]="helpLinkURL"
  [dropDownFilter]="dropDownFilter"
  [searchUIOptions]="searchUIOptions"
  [addNewButton]="addNewButton"
  [tableColumns]="bankStatementsByUploadColumns"
  [showTitleBar]="true"
  (smHandleUpdateButtonClick)="handleUpdateButtonClick($event)"
  [showPagination]="false"
  [selectInputDivClass]="'col-md-6'"
></lib-table>
