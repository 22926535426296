<div class="body-login">
    <section id="login-view" class="center-block login-view">
        <div class="center-block container login-container" [style]="{width:'600px'}">
            <div class="login-logo-container logo">
                <!-- <img src={{loginScreenMainLogoUrl}} class="img-responsive loginLogo center-block" /> -->
            </div>
            <div class="row">
                <div class="form-group">
                    <div class="control-label col-md-4"></div>
                    <div class=" text-center">
                        <h4 class="login-header">Complete Registration</h4>
                    </div>
                </div>
            </div>
            <div *ngIf="isRegistrationComplete===false" class="alert alert-dismissible alert-forgotpassword">
                <p>Please enter your <span style="font-weight: bold;">Company Login Name</span> and <span
                        style="font-weight: bold;">Username</span> below.
            </div>
            <form class="form-signin"  #sendOtpForm="ngForm"  novalidate>
                <div *ngIf="!isRegistrationComplete===true">
                    <div class="row">
                        <div class="col-md-10">
                            <lib-text-input [smLabel]="'resources.login-label-companyloginname'" [smLabelClass]="'col-md-5'" [smName]="'companyloginname'" [smType]="'text'" [(smModel)]="companyDetails.companyloginname" [smRequired]="true" [smDisabled]="completeRegistrationToken" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <lib-text-input [smLabel]="'resources.login-label-username'" [smLabelClass]="'col-md-5'" [smName]="'username'" [smType]="'text'" [(smModel)]="companyDetails.username" [smRequired]="true" [smDisabled]="completeRegistrationToken" />
                        </div>
                    </div>
                    <div class="text-center d-flex justify-content-center" style="padding-top: 10px;margin-left: 80px;">
                        <lib-checkbox-input [smInputClass]="'chk-secondary'" [smLabelClass]="'col-md-6'" [smName]="'termsAndConditionsChecked'" (smModel)="termsAndConditionsChecked" (smModelChange)="checkEvent()" (smRequired)="true" />
                        <span>I accept the 
                            <lib-link  [linkName]="'Terms and Conditions'" [translate]="'Terms and Conditions'" [url]="termsAndConditionsUrl" [target]="'_blank'"/>
                        </span>
                    </div>
                    <div *ngIf="termsAndConditionsChecked===true">
                        <div *ngIf="isRegistrationComplete===false" class="row">
                            <div class="alert alert-dismissible alert-forgotpassword">
                                <p>Then enter your new password. Remember passwords must be at least <span
                                        style="font-weight: bold;">10 characters long</span> and contain at least <span
                                        style="font-weight: bold;">1 special character</span>. When done request a One Time
                                    PIN (OTP) for verification.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10">
                                <lib-text-input [smLabel]="'Password'" [smLabelClass]="'col-md-5'" [smName]="'password'" [smType]="'password'" [(smModel)]="companyDetails.password" [smRequired]="true" />
                            </div>
                         </div>
                        <div class="row">
                            <div class="col-md-10">
                                <lib-text-input [smLabel]="'Confirm Password'" [smLabelClass]="'col-md-5    '" [smName]="'confirmPassword'" [smType]="'password'" [(smModel)]="companyDetails.confirmPassword" [smRequired]="true" />
                            </div>
                         </div>
                        <div class="row">
                            <div class="form-group d-flex justify-content-center" style="margin-left: 10px;">
                                <div class="control-label col-md-6">
                                    <lib-button [smType]="'button'" [smLabel]="'Send OTP Email'" (smClick)="sendOtpEmail(companyDetails, sendOtpForm)" [smClass]="'btn btn-sm btn-quaternary btnSecondaryAction'" />
                                    <lib-button [smType]="'button'" [smLabel]="'Send OTP SMS'" (smClick)="sendOtpSms(companyDetails, sendOtpForm)" [smClass]="'btn btn-sm btn-quaternary btnSecondaryAction'" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div *ngIf="isOtpSent===true && isRegistrationComplete===false"
                class="alert alert-dismissible  alert-forgotpassword">
                <p>Your OTP has been sent to you via the relevant channel. If you do not receive it please double check the
                    <span style="font-weight: bold;">Company Login Name</span> and <span
                        style="font-weight: bold;">Username</span> that you have entered and verify that they are correct.
                </p>
                <p>Once you receive your OTP, please enter it below.</p>
            </div>
            <form (ngSubmit)="completeRegistration(companyDetails, completeRegistrationForm, sendOtpForm)"
                class="form-signin"  #completeRegistrationForm="ngForm"  novalidate>
                <div *ngIf="isOtpSent===true && isRegistrationComplete===false">
                    <div class="row">
                        <div class="col-md-10">
                            <lib-text-input [smLabel]="'OTP'" [smLabelClass]="'col-md-5'" [smName]="'otp'" [smType]="'text'" [(smModel)]="companyDetails.otp" [smRequired]="true" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group d-flex" style="margin-left: 22px;">
                            <div class="control-label col-md-7 ">
                                <lib-button [smType]="'submit'" [smLabel]="'Complete Registration'" [smClass]="'btn btn-sm btn-primary btnPrimaryAction'" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="alert alert-dismissible alert-forgotpassword" *ngIf="isRegistrationComplete===true">
                <p>Your registration is complete. You should receive a Welcome email.</p>
                <p><a href="login" class="glyphicon-color forgotpasswordlink" style="color: #666;font-weight: bold;"
                        translate="resources.forgottenpwd-clickheretologin-msg"></a></p>
            </div>
        </div>
    </section>
</div>
