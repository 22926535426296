<div class="modal-header ">
    <h3 class="modal-title pull-left"><i class="fa fa-times"></i> Suspend Company - {{params.company.sanitizedName}}</h3>
    <lib-button [smType]="'button'" (smClick)="cancel()" [smClass]="'btn-close pull-right'" />
</div>
<form class="form-horizontal"  #frm="ngForm"  novalidate>
    <div class="modal-body">
        <div class="row">
            <div class="form-group d-flex">
                <label class="control-label col-md-4 mt-1 align-label">Suspension Reason</label>
                     <div class="col-md-6">
                        <lib-select-input [smOptions]="options" [smOptionValueField]="'value'" [smOptionDisplayField]="'display'" [smLabelClass]="'col-md-3'" [smName]="'deactivationReason'" [(smModel)]="deactivationReason" (smModelChange)="deactivationReasonChanged()" />
                    </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group d-flex">
                <label class="control-label col-md-4 mt-2 align-label">Comment</label>
                     <div class="col-md-6">
                        <lib-textarea-input [smLabelClass]="'col-md-3'" [smName]="'comment'" [(smModel)]="comment" [smMaxlength]="200" [smRows]="4" [smRequired]="true" />
                    </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-bar button-bar-modal">
            <lib-button [smLabel]="'Cancel'" [smType]="'button'" (smClick)="cancel()" [smIcon]="'fa fa-minus'" [smClass]="'btn btn-primary'" />
            <lib-button [smLabel]="'Suspend'" [smType]="'submit'" (smClick)="ok(frm)" [smIcon]="'fa fa-times'" [smClass]="'btn btn-secondary'" />
        </div>
    </div>
</form>

