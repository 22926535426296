import { Component } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DataService } from "packages/shared-lib/src/lib/services/data.service";
import { MessagingService } from "packages/shared-lib/src/lib/services/messaging.service";
import { NotificationBarService } from "packages/shared-lib/src/lib/services/notification-bar.service";
import { DateService } from "packages/shared-lib/src/lib/services/date.service";
import { webPortal } from "packages/shared-lib/src/lib/services/api/webportal.api";
import { Router } from "@angular/router";
import { portalConstants } from "packages/shared-lib/src/lib/constants/portal.constants";


@Component({
  selector: "lib-new-bulk-licensees-modal",
  templateUrl: "./new-bulk-licensees-modal.component.html",
})
export class NewBulkLicenseesModalComponent {
  constructor(private router: Router, private dateService: DateService, private dataService: DataService, private activatedRoute: ActivatedRoute, private messagingService: MessagingService, public activeModal: NgbActiveModal, private notificationBarService: NotificationBarService) {
    this.activatedRoute.params.subscribe(params => {
      this.licenseeId = parseInt(params['id']);
    })
  }
  params: any = '';
  licenseeId = 0;
  licenseeName = ''
  formData: any = {
    numberofvouchers: undefined,
    daysValidFor: undefined,
    redemptionDaysValidFor: '',
    redemptionStartDateDP: undefined,
    redemptionStartDate: undefined,
    invoicenumber: undefined,
    licenseeId: this.licenseeId,
    title: this.licenseeName
  }
  
  redemptionDaysValidFor = portalConstants.redemptionDaysValidForOptions;

  closeModal() {
    this.activeModal.dismiss();
  }

  save(frm: any) {
    this.messagingService.broadcastCheckFormValidatity();
    if (!frm.invalid) {
      this.formData.redemptionStartDate = this.dateService.getFormattedDateForWebApi(this.formData.redemptionStartDateDP);
      this.dataService.post(webPortal.saveBulkLicenses, this.formData).subscribe(() => {
        this.notificationBarService.success('New Bulk Licenses added successfully');
        this.activeModal.close();
      });
    }
  }
  ngOnInit() {
    this.formData.title = this.params.licenseeName
    this.formData.licenseeId = this.params.licenseeId
    this.formData.redemptionStartDateDP = this.dateService.getTodaysDate()
  }
}