<div class="modal-header">
    <h3 class="modal-title pull-left"><i class="fa fa-envelope-o"></i> {{params.title}}</h3>
<lib-button [smType]="'button '" (smClick)="cancel()" [smClass]="'btn btn-close pull-right '"  />
</div>
<form class="form-horizontal"  #frm="ngForm"  novalidate>
    <div class="modal-body">
        <div class="row">
            <lib-text-input [smLabel]="'To'" [smLabelClass]="'col-md-1'" [smName]="'toEmail'" [smType]="'text'" [(smModel)]="toEmail" [smRequired]="true"/>
            <span class="help-block" *ngIf="!isEmailValid">(Invalid Email Address)</span>
        </div>
        <div class="position-relative">
            <i class="fa fa-question-circle fa-lg position-absolute" 
            title="You can enter additional email addresses seperated by a semicolon"></i>
        </div>
    </div> 
    <div class="modal-footer">
        <div class="button-bar button-bar-modal">
            <lib-button [smType]="'button'" (smClick)="cancel()" [smIcon]="'fa fa-minus'" [smClass]="'btn btn-primary'" [smLabel]="'Cancel'"/>
            <lib-button [smType]="'submit'" (smClick)="ok(frm)" [smIcon]="'fa fa-envelope-o'" [smClass]="'btn btn-secondary'" [smLabel]="'Email'" />
        </div>
    </div>
</form>

