export const app_env = {
  "serverBaseUrl": "https://smeasy-apim-ne-test.azure-api.net",
  "environment": "local",
  "version": "Release-52",
  "showEnvDetails": false,
  "api_auth_key": "9659D80F-B17C-4507-A7FA-419E43D5AB89", 
  "x_apim_key": "9d3e112134d345d0809a644ca72530b9",
  "showLanguageSelector": true,
  "topLeftLogoUrl": "assets/images/smeasy_logo.png",
  "loginScreenMainLogoUrl": "assets/images/smeasy_logo.png",
  "footerPoweredBySmeasyLogoUrl":"",
  "supportVideoBaseUrl": "http://support.smeasy.co.za/",
  "supportLinkUrl": "http://support.smeasy.co.za/enZA/",
  "appTitle": "SMEasy",
  "googleAnalyticsTrackingId": "UA-165685349-1",
  "customstyle": "z_smeasy.less",
  "favicon": "assets/favicons/smeasy-favicon.ico",
  "progressCircleHighlightColor": "#2196f3",
  "spendingAppPieChartColours": ["#CAE1F1", "#95C3E3", "#61A5D5", "#3E92CC", "#3378A7", "#2E6B98", "#285D82", "#225070", "#1D435D", "#17364B"],
  "fanExamId": null,
  "termsAndConditionsUrl": "https://www.smeasy.co.za/docs/terms-and-conditions.pdf",
  "isYodleeEnabled" : false,
  "loginDisclaimerText" : "",
  "production": false
};
export const absa_env = {
  "serverBaseUrl": "https://smeasy-apim-ne-test.azure-api.net",
  "environment": "local",
  "version": "Release-52",
  "showEnvDetails": false,
  "api_auth_key": "9659D80F-B17C-4507-A7FA-419E43D5AB89", 
  "x_apim_key": "9d3e112134d345d0809a644ca72530b9",
  "showLanguageSelector": true,
  "topLeftLogoUrl": "assets/images/absa_smeasy_logo.png",
  "loginScreenMainLogoUrl": "assets/images/absa_login_logo.png",
  "footerPoweredBySmeasyLogoUrl": "assets/images/poweredbysmeasy_logo.png",
  "supportVideoBaseUrl": "http://support.smeasy.co.za/",
  "supportLinkUrl": "http://support.smeasy.co.za/absa/",
  "appTitle": "Absa SMEasy",
  "googleAnalyticsTrackingId": "G-LJMZ65X2X2",
  "customstyle": "z_smeasy.less",
  "favicon": "assets/favicons/absa-favicon.ico",
  "progressCircleHighlightColor": "#2196f3",
  "spendingAppPieChartColours": ["#F52D28", "#FA551E", "#F47721", "#F05A78", "#F0325A", "#DC0032", "#AA052D", "#870A3C", "#500A28", "#000000"],
  "loginDisclaimerText": "The SMEasy value added services are brought to you by Absa Bank Ltd Reg No 1986/004794/06 and is powered by SMEasy. Absa is an Authorised Financial Services and Registered Credit Provider Reg No NCRCP7",
  "fanExamId": null,
  "termsAndConditionsUrl": "https://www.absa.co.za/content/dam/south-africa/absa/aol/Absa-7108-EX.pdf",
  "isYodleeEnabled" : false,
  "production": false
};
export const portal_env = {
  "serverBaseUrl": "https://testportalapi.smeasy.co.za",
  "environment": "local",
  "version": "Release-52",
  "showEnvDetails": false,
  "api_auth_key": "902C77A0-753D-4B2E-B604-D0FAB805BDE5", 
  "x_apim_key": "9d3e112134d345d0809a644ca72530b9",
  "production": false
}