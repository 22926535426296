<lib-table 
[tableColumns]="supplierColumns" 
[title]="title"
[api]="api" 
[exportButton]="exportButton"
[addNewButton]="addNewButton"
[pdfButton]="true"
[refreshButton]="refreshButton"
[addNewButton]="addNewButton"
[helpLinkURL]="supportVideoBaseUrl"
[tabData]="tabData"
[resourceMessages]="resourceMessages"
[totalColumn]="totalColumn"
[additionalGetPageResponse]="additionalGetPageResponse"
[searchUIOptions]="searchUIOptions"
[dateFilter]="dateFilter"
[singleDateFilterDivClass]="'col-md-6'"
>
</lib-table>