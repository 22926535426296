<div class="modal-header clearfix">
    <h3 class="modal-title pull-left"><i class="fa fa-times"></i> Extend Subscription Expiry - {{params.company.sanitizedName}}</h3>
    <lib-button [smType]="'button'" (smClick)="cancel()" [smClass]="'btn-close pull-right'" />
</div>
<form class="form-horizontal"  #frm="ngForm"  novalidate>
    <div class="modal-body">
        <div class="row">
            <lib-select-input [smLabel]="'Days to extend by'" [smOptions]="options" [smOptionDisplayField]="'display'" [smOptionValueField]="'value'" [smLabelClass]="'col-md-4'" [smName]="'daysToExtendBy'" [(smModel)]="daysToExtendBy" [smRequired]="true" />
        </div>
        <div class="row">
            <lib-textarea-input [smLabel]="'Reason'" [smLabelClass]="'col-md-4'" [smName]="'reason'" [(smModel)]="reason" [smMaxlength]="200" [smRows]="4" [smRequired]="true" />
        </div>
    </div>
    <div class="modal-footer">
        <div class="button-bar button-bar-modal">
            <lib-button [smLabel]="'Cancel'" [smType]="'button'" (smClick)="cancel()" [smIcon]="'fa fa-minus'" [smClass]="'btn btn-primary'" />
            <lib-button [smLabel]="'Extend Expiry'" [smType]="'submit'" (smClick)="ok(frm)" [smIcon]="'fa fa-times'" [smClass]="'btn btn-secondary'" />
        </div>
    </div>
</form>

