<form #HeaderForm="ngForm" novalidate>
<nav class="d-flex headerBar fixed-top navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid resp-container">
      <a class="navbar-brand" href="/dashboard"><img class="img-responsive companyLogo" [src]="topLeftLogoUrl"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="col-md-9 align-sec">        
          <ul class="navbar-nav me-auto pt-2 mb-lg-0 d-flex justify-content-between">
          <ng-container *ngIf="!isAdminSectionEnable">
          <li class="">
            <a class="nav-link" [routerLink]="['/settings']" href="#" translate="resources.layout-settings-menu-settings"></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [href]="helpLinkUrl" target="_blank" translate="resources.layout-settings-menu-support"></a>
          </li>
        </ng-container>
        <ng-container *ngIf="isAdminSectionEnable">
          <li class="text-nowrap"><h4 style="padding: 10px;">MANAGEMENT PORTAL</h4></li>
          <li class="d-flex align-items-center justify-content-end px-2">
            <div class="mb-1">
              <lib-select-input
              [smSelectWithSearchOption]="true"
                [smOptions]="companies"
                [smOptionDisplayField]="'sanitizedName'"
                [smOptionValueField]="'id'"
                [(smModel)]="selectedCompany"
                (smModelChange)="onSelect()"
                [smPlaceholder]="'Please select a company'"
                [smName]="'company'"
                [isSelectSearch]="true"                
              ></lib-select-input>
            </div>
          </li>
          <li class="d-flex align-items-start pt-2">
            <lib-button  (smClick)="refreshData()" [smClass]="'btn btn-primary'" [smIcon]="'fa fa-refresh'"></lib-button>
          </li>
        </ng-container>
        </ul>
      </div>
        <div class="">
        <div class="d-flex justify-content-center">
            <ul class="navbar-nav me-auto mb-lg-0 align-items-center">
                <li class="nav-item" >
                  <a class="loggedin-text nav-link">
                    <span class="hidden-sm" *ngIf="!isAdminSectionEnable">
                        <b>{{getUsername()}}</b>&nbsp;<span translate="resources.common-of-label"></span>&nbsp;<b>{{getCompanyLogin()}}</b>
                    </span>
                    <span class="hidden-sm mt-0 text-nowrap" *ngIf="isAdminSectionEnable">
                      <span id="resp-label">Logged in as</span>&nbsp;&nbsp;<b>{{getPortalUsername()}}</b>
                    </span>
                </a>
                </li>
                <li class="nav-item">
                    <!-- <button (click)="logout()" class="btn btn-secondary btn-sm header-logout">Logout</button> -->
                    <div class="header-logout">
                      <lib-button [smLabel]="'Logout'" (smClick)="logout()" [smClass]="isAdminSectionEnable ? 'btn btn-custom1 admin-logout' : 'btn btn-custom1 btn-sm no-margin'"></lib-button>
                    </div>
                </li>
                <li class="nav-item" *ngIf="!isAdminSectionEnable">
                    <a class="navbar-brand" *ngIf="companyProfileImage" ><img class="img-responsive companyLogo2" [src]="'data:image/png;base64,'+companyProfileImage"></a>
                </li>
            </ul>
        </div>
      </div>
      </div>
    </div>
  </nav>
</form>