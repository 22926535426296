<div class="form-group row">
  <label class="control-label" [ngClass]="smLabelClass">
      <span [translate]="smLabel"></span>
  </label>
  <div [ngClass]="elementClass">
      <label class="form-control-static" [ngClass]="smElementClass">
          <strong *ngIf="smStrong" [innerHtml]="(smType==='amount')? (smValue | number:'1.2-2'): smValue"></strong>
          <span *ngIf="!smStrong"  [innerHtml]="(smType==='amount')? (smValue | number:'1.2-2'): smValue"></span>
      </label>
  </div>
</div>