 <lib-table 
[tableColumns]="supplierColumns" 
[totalColumn]="totalColumn"
[api]="api" 
[helpLinkURL]="helpLinkUrl"
[title]="title" 
[exportButton]="exportButton"
[addNewButton]="addNewButton"
[pdfButton]="true"
[searchUIOptions]="searchUIOptions"
[tabData]="tabData" 
[resourceMessages]="resourceMessages"
[dropDownFilter]="dropDownFilter"
(smHandleDropdownClick)="dropDownHandle($event)"
[additionalGetPageResponse]="additionalGetPageResponse"
[emptyDefaultDates]="true"
[dateRangeDivClass]="'col-md-6'"
[searchInputDivClass]="'col-md-6'"
[selectInputDivClass]="'col-md-6'"
>
</lib-table>