import { inject } from "@angular/core";
import { AuthService } from "../auth.service";
import { ActivatedRouteSnapshot, Router } from "@angular/router";

export const authGuard = (route: ActivatedRouteSnapshot) => {

  
    const authService = inject(AuthService);
    const router = inject(Router);
    const permission = route.data["permission"];
    const title = route.data["title"];
    if (authService.isAuthenticated()) {
      if(permission !== undefined) {
        if(authService.hasRequiredPermission(permission)) {
          return true;
        } else {
          return router.navigate(['/insufficientpermissions'], { fragment: title });
        }
      } else {
        return true;
      }
    } 
    
    // Redirect to the login page
    return router.parseUrl('/login');
};

export const portalAuthGuard = () => {
  const authService = inject(AuthService);
    const router = inject(Router);
    
    if (authService.isPortalAuthenticated()) {
        return true;
      } 
    
    // Redirect to the login page
    return router.parseUrl('/login');
}