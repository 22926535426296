import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ThemeLoaderService } from 'packages/shared-lib/src/lib/services/theme-loader.service'
import { setTheme } from 'ngx-bootstrap/utils';
import { SpinnerService } from 'packages/shared-lib/src/lib/services/spinner.service';
import BigNumber from "bignumber.js";
import { webConstants } from 'packages/shared-lib/src/lib/constants/web.constants';

// declare google analytics
declare const gtag: any;
@Component({
  selector: 'web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent{



  showMainSplashScreen=true
  showSpinner=true;

  title = 'SMEazy.WebClient';
  url:string = this.router.url
  env : any = {}

  constructor(
              private router: Router,
              private translateService: TranslateService,
              private themeLoaderService: ThemeLoaderService,
              private spinnerService: SpinnerService,
            ) {

    this.env=webConstants.currentEnv;
    this.addGAScript();
    setTheme('bs5');
    BigNumber.config({ DECIMAL_PLACES: 2,ROUNDING_MODE: 2 })      
    router.events.subscribe((event:any) => {
      if(event instanceof NavigationEnd)
        this.url=event.urlAfterRedirects
        if (this.env.googleAnalyticsTrackingId && this.env.googleAnalyticsTrackingId.length > 0) {
          gtag('config', this.env.googleAnalyticsTrackingId, { 'page_path': event.urlAfterRedirects });
      }
    })

    translateService.setDefaultLang('en')
    translateService.use('en').subscribe(()=>{
      this.showMainSplashScreen=false
    })

    this.spinnerService.httpProgress().subscribe((status: boolean) => {
      setTimeout(() => {
        this.showSpinner=status;
      }, 0);
    });
    
    this.themeLoaderService.load(webConstants.currentTheme);
  }

   /** Add Google Analytics Script Dynamically */
   addGAScript(){
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.env.googleAnalyticsTrackingId;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', this.env.googleAnalyticsTrackingId, { send_page_view: false });
  }

  ngOnInit(){
    const favIcon: any = document.querySelector('#favIcon'); 
    favIcon.href = webConstants.currentEnv.favicon; 
  }
}