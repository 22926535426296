<div [ngClass]="{'danger' : includeDangerHeader}" class="modal-header ">
    <h3 class="modal-title pull-left"><i [ngClass]="(includeDangerHeader || isFinalize)?'fa fa-question-circle':'fa fa-exclamation-triangle'"></i> 
      <span class="nameSpace"> {{modalInstance.title}}</span>
    </h3>
    <lib-button
    [smTitle]="'button'"
    [smClass]="'btn btn-close pull-right'"
    (smClick)="closeModal()"
  />
</div>
<div class="modal-body">
    <p>{{modalInstance.message}}</p>
</div>
<div class="modal-footer">
    <div class="button-bar">
       
      
    <lib-button
    [smLabel]="noText"
    [smType]="'button'"
    [smClass]="'btn btnSecondaryAction btn-quaternary'"
    (smClick)="closeModal()"
  />
        <lib-button
      [smLabel]="yesText"
      [smType]="'button'"
      [smClass]="'btn btnSecondaryAction btn-secondary yes-button'"
      (smClick)="passBack()"
      
    />

    </div>
</div>

