import { Component, Input, OnInit } from "@angular/core";
import { SessionService } from "../../../services/session.service";
import { AuthService } from "../../../services/auth.service";
import { LookupDataService } from "../../../services/lookup-data.service";
import { webPortal } from "../../../services/api/webportal.api";
import { DataService } from "../../../services/data.service";
import { PortalSessionService } from "../../../services/portal/session.service";
import { MessagingService } from "../../../services/messaging.service";
import { NavigationService } from "../../../services/navigation.service";
import { webConstants } from "../../../constants/web.constants";
declare const window: any;
@Component({
  selector: "lib-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  userName: string | undefined;
  companyName: string | undefined;
  getCompanyLogin = ()=> { return this.sessionService.companyname; };
  getUsername = ()=> { return this.sessionService.username; };
  getPortalUsername = ()=> { return this.portalSessionService.username; };
  companyProfileImage: any;
  companies: any = [];
  selectedCompany:any = null;
  topLeftLogoUrl = '';
  helpLinkUrl = webConstants.headerHelpvideUrl;
  companyProfile:any

  @Input() isAdminSectionEnable = false;

  constructor(
    private sessionService: SessionService,
    private portalSessionService: PortalSessionService,
    private authService: AuthService,
    private lookupDataService: LookupDataService,
    private dataService: DataService,
    private messagingService: MessagingService,
    private navigationService: NavigationService,
  ) {
    this.messagingService.listenCompanyProfileSaved(
      this.getProfileImage.bind(this)
    );
    this.messagingService.listenForLoginSuccess(this.getCompanyCache.bind(this))
  }

  ngOnInit() {
    this.topLeftLogoUrl = webConstants.currentEnv.topLeftLogoUrl;
    if (this.isAdminSectionEnable) {
      if(this.authService.isPortalAuthenticated()) {
        this.getData(true);
      }
    } else {
      if (this.getUsername() && this.getCompanyLogin()) {
        this.lookupDataService
          .getCompanyProfileImage(true, false)
          .subscribe((data: any) => {
            this.companyProfileImage = data;
          });
      }
    }
  }

  logout() {
    if (this.isAdminSectionEnable) {
      this.authService.portalLogout();
    } else {
      this.authService.logOut();
    }
  }

  getData(refresh?:boolean) {
    this.dataService
      .getLookupData(webPortal.getCompanies, refresh)
      .subscribe((result: any) => {
        this.companies = result;
      });
  }

  onSelect() {
    this.navigationService.goToCompany(this.selectedCompany);
  }

  refreshData() {
    this.getData(true);
  }

  getProfileImage(data: any) {
    this.companyProfileImage = data;
  }

   getCompanyCache() {
    this.lookupDataService.getCompanyProfile(true,false).subscribe((response:any)=>{
      this.companyProfile = response 
      this.engageFanExam(this.companyProfile.primaryContactEmail)
    })
  }

  engageFanExam(email:any) {
    console.log('About to connect to FanExam using Id: ', webConstants.currentEnv.fanExamId, email);
    if (webConstants.currentEnv.fanExamId && email) {
        window.$FE = {id:webConstants.currentEnv.fanExamId};
        // The identifier $FE.email is a JavaScript string. Backslashes must be escaped as double backslashes.
        // $FE.email is not case sensitive: "John@example.com" and "john@example.com" are equal.
        window.$FE.email = email; // input user unique identifier - email recommended - in these quotes (not case sensitive)
        window.$FE.tags = ""; // tags go in these quotes separated by commas
        window.$FE.lang = "en-us"; // language + locale to be used
        ((d)=> {
            var ca = d.createElement("script");
            ca.type = "text/javascript";
            ca.async = true;
            ca.src = "//tag.fanexam.com/exam.js";
            var t:any = d.getElementsByTagName("script")[0];
            t.parentNode.insertBefore(ca, t);
        })(document);
    }
  }
}
